@charset "utf-8";
/* ----------------------------------
Name: custom-style.css
Version: 1.0
-------------------------------------
Table of contents
    01. Reset
    02. Banner
    03. Design
    04. Plan
    05. Carousel
    06. Accordion
    07. Testimonials
    08. Partners
    09. Contact Form
    10. Support
    11. Sub Banner
    12. Breadcrumb
    13. Custom Style For Other Pages
    14. Buttons (Path:assets/css/buttons)
        14.1 Yellow Button
        14.2 Transparent Button
    15. Fonts (Path:assets/css/fonts)
        15.1 Roboto Slab
        15.2 Poppins
    16. Header Style (Path:assets/css/header)
        16.1 Header Responsive
    17. Footer Style (Path:assets/css/footer)
        17.1 Footer Responsive
    18. Typography (Path:assets/css/typography)
        18.1 Short Code
        18.2 Progress Bar
        18.3 Site Map
        18.4 Testimonial
        18.5 Faq
        18.6 Tabs
        18.7 Accordion
        18.8 Carousel
        18.9 Plans
        18.10 Table
        18.11 Definition Lists
    19. Blog (Path:assets/css/blog)
        19.1 Single Post
        19.2 Single Post Responsive
    20. Blog Side Bar (Path:assets/css/blog-sidebar)
        20.1 Blog Side Bar
        20.2 Blog Side Bar Responsive
*/

/* ===================================
    01. Reset
====================================== */

body{margin:0;padding:0;background:repeat-x top #fff; font-family:'微軟正黑體',sans-serif; overflow-x: hidden; font-smoothing：antialiased;}
body,select,input{color:#666;font-size:14px;line-height:24px;}
img{max-width:100% !important; height:auto; border:none}

::-moz-selection{color:#fff;background:#000}
::selection{color:#fff;background:#000}

figure{margin:0}

.group:after{content:"";display:table;clear:both}

a{outline:none;color:#333;text-decoration:none}
a:hover{outline:none;-webkit-transition:all .6s ease-in-out;-moz-transition:all .6s ease-in-out;-ms-transition:all .6s ease-in-out;-o-transition:all .6s ease-in-out;transition:all .6s ease-in-out;text-decoration:underline}

/* ===================================
    02. Banner
====================================== */

.banner-con{height:764px;background:url(/images/banner.jpg) no-repeat center top;background-size:cover}
.banner-text{/*height:100vh;display:table;*/ font-size:18px; position: relative;}
.banner-text > div{/*display:table-cell;vertical-align:middle;*/ width: 100%; margin: 334px auto 0 auto; padding:20px;}
.banner-text h3{margin:0 0 22px}
.banner-text h1{font-size:44px; text-align: center; letter-spacing: 5px; line-height: 1.3em; text-shadow: 0 0 10px rgba(0,0,0,1) !important;}
.banner-text h1 br, .br_mobile{ display: none; }
.banner-text p{ font-size:26px; line-height: 1.5em; margin:30px 0 44px; text-align: center; letter-spacing: 1px; text-shadow: 0 0 10px rgba(0,0,0,1) !important;}
.banner-text p br{ display: none !important;}
.banner-text .yellow-btn a{padding:20px 32px;font-size:18px}
#bannerSlider .carousel-control{opacity:1;top:48%}
#bannerSlider .carousel-control .glyphicon{display:none}
#bannerSlider .left.carousel-control,#bannerSlider .right.carousel-control{width:46px;height:46px}
#bannerSlider .left.carousel-control{left:50px}
#bannerSlider .left.carousel-control .sr-only{left:0}
#bannerSlider .right.carousel-control{right:50px}
#bannerSlider .right.carousel-control .sr-only{right:0}
#bannerSlider .carousel-control .sr-only{width:100%;color:#fff;clip:inherit;height:100%;font-size:10px;line-height:46px;letter-spacing:2px;text-align:center;text-transform:uppercase;border:1px solid #fff}
#bannerSlider .carousel-control .sr-only:hover{opacity:.7}

/* ===================================
    03. Design
====================================== */

.board-con.padding-top{ padding-top:100px !important; }
.board-con.padding-bottom{ padding-bottom:100px !important; }
.content-box{float:left;width:100%}
.content-box ul,.content-box ol{margin: 0;padding: 0;list-style: none}
.content-box h2{margin:0 0 25px;font-size:42px;line-height:44px}
.design{ padding-bottom:25px; font-size:16px}
.design h3{margin:35px 0}
.design .transparent-btn{margin:42px 0 0}
.design .transparent-btn a,.wood-box .transparent-btn a{padding:20px 40px;font-size:18px}

/* ===================================
    04. Plan
====================================== */

.plan-con{background:url(/images/services.jpg) no-repeat center top;background-size:cover;background-attachment:fixed}
.services-box{width:100%;float:left;padding:10px 0;background:#fff}
.services-fig{width:162px;height:18px;margin:0 auto 28px;background:url(/images/services_single.png) no-repeat center top}
.services-box h3{margin-top: 0;}
.services-box p{margin:25px 0 30px}
.services-box:hover{color:#431f09;background-color:#f1b22c}
.services-box:hover .yellow-btn a{background-color:#431f09}
.services-box:hover .services-fig{background:url(/images/services_single2.png) no-repeat center top}
#woodart{margin:65px 0 0;border:none;padding:0}

/* ===================================
    05. Carousel
====================================== */

#woodart .carousel-control,#testimonials .carousel-control{float:right;width:50%;position:relative}
#woodart .carousel-control-prev,#testimonials .carousel-control-prev{height:45px;width:45px;bottom:50px;left:15px;position:absolute;background:url(//assets/images/previous_img.png) no-repeat center}
#woodart .carousel-control-prev:hover{background:url(//assets/images/previous_imghover.png) no-repeat center}
#woodart .carousel-control-next,#testimonials .carousel-control-next{height:45px;width:45px;bottom:50px;left:75px;position:absolute;background:url(//assets/images/next_img.png) no-repeat center}
#woodart .carousel-control-next:hover{background:url(//assets/images/next_imghover.png) no-repeat center}
.art-textbox{line-height:30px;margin:85px 0 0}
.art-textbox h3{font-size:30px;margin:0 0 25px}
.carousel-fade .carousel-inner .item{transition-property:opacity}
.carousel-fade .carousel-inner .item,.carousel-fade .carousel-inner .active.left,.carousel-fade .carousel-inner .active.right{opacity:0}
.carousel-fade .carousel-inner .active,.carousel-fade .carousel-inner .next.left,.carousel-fade .carousel-inner .prev.right{opacity:1}
.carousel-fade .carousel-inner .next,.carousel-fade .carousel-inner .prev,.carousel-fade .carousel-inner .active.left,.carousel-fade .carousel-inner .active.right{left:0;transform:translate3d(0,0,0)}
.carousel-fade .carousel-control{z-index:2}

/* ===================================
    06. Accordion
====================================== */

.faq-con{background:url(/images/faqs_bg.jpg) no-repeat center top;background-size:cover;background-attachment:fixed}
#accordion.panel-group{margin:0}
#accordion.panel-group .panel + .panel{margin:10px 0 0}
#accordion.panel-group .panel + .panel:first-child{margin:0}
#accordion.panel-group .panel{border-radius:0;box-shadow:none;text-align:left;border:none;background:none}
#accordion .panel-default > .panel-heading{padding:0}
#accordion .panel-title a{display:block;font-size:16px;padding:0 20px;line-height:48px;outline:none;text-decoration:none;color:#431f09;background-color:#efaa15}
#accordion .panel-title a.collapsed{color:#fff;background-color:#431f09}
#accordion.panel-group .panel-heading + .panel-collapse > .list-group,#accordion.panel-group .panel-heading + .panel-collapse > .panel-body{border:none}
#accordion .panel-body{color:#fff;font-size:14px;line-height:22px;padding:20px 22px;border:none;background:rgba(67,31,9,0.5)}
#accordion .panel-body a{color:#f60}

.skill-level{color:#fff;font-size:18px;height:210px;display:table;margin:0 0 30px;background:#431f09}
.skill-level > div{display:table-cell;vertical-align:middle}
.skill-level .numeric{font-size:42px;line-height:42px;display:block;margin:0 0 20px}
.skill-level:hover{color:#431f09;background:#efaa15}
.skill-level:hover .numeric{color:#431f09}

/* ===================================
    07. Testimonials
====================================== */

#testimonials{margin:50px 0 0;font-size:18px;line-height:30px}
.client-name{height:160px;display:table;font-size:30px;padding:0 0 0 32px}
.client-name span{display:table-cell;vertical-align:middle}
#testimonials p{margin:45px 0 0}
#testimonials .carousel-control{width:100%}
#testimonials .carousel-control-prev,#testimonials .carousel-control-next{bottom:-80px}

/* ===================================
    08. Partners
====================================== */

.partners-logo{height:160px;display:table;margin:30px 0 0;background-color:#fff;border:1px solid #000}
.partners-logo figure{display:table-cell;vertical-align:middle}

/* ===================================
    09. Contact Form
====================================== */

.contact-box{font-size:16px}
.contact-form{margin:50px 0 0}
.contact-form ul{float: left;width: 100%;margin: 0;padding: 0}
.contact-form ul li,.comment-box{float:left;width:48%;margin:0 0 20px;position:relative}
.contact-form ul li{padding: 0;list-style: none}
.contact-form ul li:nth-child(2n){float:right}
.contact-form ul li input,.contact-form ul li select,.comment-box textarea{float:left;color:#666;font-size:14px;width:100%;height:50px;padding:10px;box-shadow:none;background:none;border:1px solid #ddd}
.comment-box{width:100%;margin:0 0 30px}
.comment-box textarea{height:230px}
.contact-form ul li input::-webkit-input-placeholder,.contact-form ul li select::-webkit-input-placeholder,.comment-box textarea::-webkit-input-placeholder{color:#666}
.contact-form ul li input:-moz-placeholder,.contact-form ul li select:-moz-placeholder,.comment-box textarea:-moz-placeholder{color:#666;opacity:1}
.contact-form ul li input::-moz-placeholder,.contact-form ul li select::-moz-placeholder,.comment-box textarea::-moz-placeholder{color:#666;opacity:1}
.contact-form ul li input:-ms-input-placeholder,.contact-form ul li select:-ms-input-placeholder,.comment-box textarea:-ms-input-placeholder{color:#666;color:#666}
.form-success{color:#19a937;float:left;font-size:16px;font-weight:700;margin:0 0 15px;text-align:left}
.error{color:#f90303;padding:0 15px}

/* ===================================
    10. Support
====================================== */

/*.support{padding:45px 0 35px;background:url(/images/support-bg.jpg) no-repeat center top;background-size:cover}*/
.call-us h3{font-size:30px;line-height:36px}
.call-us h3 span{display:block;font-size:18px;font-family:'微軟正黑體',sans-serif}
.phone-icon{width:60px;height:60px;line-height:60px;border:1px solid #efaa15}
.phone-numbers{font-size:30px;padding:0 0 0 25px;line-height:30px}
.phone-numbers span{display:block;font-size:18px}

/* ===================================
    11. Sub Banner
====================================== */

.sub-banner-con{padding:82px 0 0;background-position:center top;background-repeat:no-repeat;background-size:cover;}
.sub-banner{height:300px;display:table}
.sub-banner h2{font-size:44px;margin:0 0 25px}
.sub-banner > div{display:table-cell;vertical-align:middle}
.about-banner{background-image:url(/images/about-banner.jpg);}
.app-banner{background-image:url(/images/app-banner.jpg);}
.app1-banner{background-image:url(/images/app1-banner.jpg);}
.app2-banner{background-image:url(/images/app2-banner.jpg);}
.app3-banner{background-image:url(/images/app3-banner.jpg);}
.app4-banner{background-image:url(/images/app4-banner.jpg);}
.app5-banner{background-image:url(/images/app5-banner.jpg);}
.know-banner{background-image:url(/images/know-banner.jpg);}
.news-banner{background-image:url(/images/news-banner.jpg);}
/* ===================================
    12. Breadcrumb
====================================== */

.breadcrumb{margin:0;padding:0;font-size:18px;background:none;border-radius:0}
.breadcrumb > .active{color:#efaa15}
.breadcrumb li a{color:#fff}
.breadcrumb > li + li:before{padding:0 5px;color:#fff;content:">";vertical-align:middle}

/* ===================================
    13. Custom Style For Other Pages
====================================== */

.about-img{margin:0 0 40px}
.about-box h2{margin:0 0 15px}
.about-box p{margin:30px 0 0}

.small-box ul li,.mailing-info{float:left;width:100%;padding:40px 25px;margin:0 0 20px;border:1px solid #efaa15}
.small-box ul li .smallbox-inner h3{margin:0 0 15px}
.small-box ul li:last-child{margin:0}

.carpenters{margin:50px 0 0}
.carpenters img{width:100%}
.carpenter-name{float:left;height:94px;padding:22px 0;background-color:#431f09}
.carpenter-name h3{color:#fff;margin: 0}
.carpenter-name h3 span{display:block;color:#efaa15;font-size:14px;margin:8px 0 0;font-family:'微軟正黑體',sans-serif}

.hide-box{top:0;left:0;width:100%;height:100%;display:none;position:absolute;background-color:rgba(67,31,9,0.8)}
.carpenters:hover .hide-box{display:block}
.carpenters:hover .carpenter-name{background-color:#efaa15}
.carpenters:hover .carpenter-name h3,.carpenters:hover .carpenter-name h3 span{color:#431f09}
.hide-box .social-icons2{height:100%;display:table}
.hide-box .social-icons2 ul{display:table-cell;vertical-align:middle}
.hide-box .social-icons2 ul li{float:none;margin:0 8px 0 0;display:inline-block;vertical-align:top}

.services-banner{background-image:url(/images/services-banner.jpg)}
/*.services{margin:20px 10px 10px}*/
.services img{margin:0 0 20px}
.services h3{margin:0 0 18px}
.wood-box{background:url(/images/wood-bg.jpg) no-repeat center top;background-size:cover}
.wood-box h2 span{display:block;font-size:18px;margin:10px 0 0;font-family:'微軟正黑體',sans-serif}
.wood-box .transparent-btn a{border:2px solid #fff}
.contact-banner{background-image:url(/images/contact-banner.jpg)}
.mailing-address{font-size:16px}
.mailing-address p{margin:0 0 72px}
.mailing-info{font-size:14px}
.mailing-info p{margin:0}
.mailing-info figure{padding:0 0 0 40px}
.mailing-info .fa{width:65px;height:65px;font-size:30px;line-height:65px}
.mailing-info{margin:0;padding:40px 0;min-height:154px}
.mailing-info a{color:#efaa15;text-decoration:none}
.mailing-info a:hover{color:#431f09}
.maps{width:100%;height:400px;pointer-events:none}
.maps iframe{width:100%}
.btn{display:inline-block;vertical-align:top;width:auto;margin-bottom:10px}
.form-control{margin-bottom:15px}
.btn-primary{background-color:#efaa15;border-color:#efaa15}
.gallery-banner{background-image:url(/images/gallery-banner.jpg)}
.gallery-box .otw_portfolio_manager-3-3,.gallery-box .otw_portfolio_manager-3-3 img{height:auto!important}
.gallery-box .otw-row.otw_portfolio_manager-portfolio-items-holder.otw_portfolio_manager-portfolio-newspaper{margin:0 auto!important}

/*** EDIT ***/
.clear{ clear: both; }
img{ max-width: 750px; height: auto; vertical-align: bottom; }

#news #menu-main-menu li:nth-child(1) a.nav-link{ color: #946134; background:#fff url('/images/bg-btn_active.gif') 0 bottom repeat-x; }
#news #menu-main-menu li:nth-child(1) a:hover.nav-link, #news #menu-main-menu li:nth-child(1) a:focus.nav-link{ color:#fff; background: #946134;}
#app #menu-main-menu li:nth-child(2) a.nav-link{ color: #946134; background:#fff url('/images/bg-btn_active.gif') 0 bottom repeat-x; }
#app #menu-main-menu li:nth-child(2) a:hover.nav-link, #app #menu-main-menu li:nth-child(2) a:focus.nav-link{ color:#fff; background: #946134;}
#know #menu-main-menu li:nth-child(3) a.nav-link{ color: #946134; background:#fff url('/images/bg-btn_active.gif') 0 bottom repeat-x; }
#know #menu-main-menu li:nth-child(3) a:hover.nav-link, #know #menu-main-menu li:nth-child(3) a:focus.nav-link{ color:#fff; background: #946134;}
#about #menu-main-menu li:nth-child(4) a.nav-link{ color: #946134; background:#fff url('/images/bg-btn_active.gif') 0 bottom repeat-x; }
#about #menu-main-menu li:nth-child(4) a:hover.nav-link, #about #menu-main-menu li:nth-child(4) a:focus.nav-link{ color:#fff; background: #946134;}
#member #menu-main-menu li:nth-child(5) a.nav-link{ color: #946134; background:#fff url('/images/bg-btn_active.gif') 0 bottom repeat-x; }
#member #menu-main-menu li:nth-child(5) a:hover.nav-link, #member #menu-main-menu li:nth-child(5) a:focus.nav-link{ color:#fff; background: #946134;}
#download #menu-main-menu li:nth-child(6) a.nav-link{ color: #946134; background:#fff url('../images/bg-btn_active.gif') 0 bottom repeat-x; }
#download #menu-main-menu li:nth-child(6) a:hover.nav-link, #member #menu-main-menu li:nth-child(5) a:focus.nav-link{ color:#fff; background: #946134;}

.board-con h3{ color: #769967; margin: 0 auto 50px auto; padding:0 0 10px 0; font-size: 30px; font-weight: bold; letter-spacing: 4px; background: url('/images/h3-border_bottome_green.gif') center bottom no-repeat; text-align: center; }
.news_wrap{ margin-top: 35px !important; border-bottom: 1px dashed #000; }
.news_wrap span{ padding: 2px 15px; font-size: 16px; color: #fff; border-radius: 5px; display: inline-block; }
.news_wrap span.bg01{ background: #769967;}
.news_wrap span.bg02{ background: #bd9a57;}
.news_wrap span.bg03{ background: #5087ad;}
.news_wrap time{ margin-left: 10px; color: #444; display: inline-block; }
.news_wrap h2{ width: 100%; margin: 0; padding: 0; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 16px; color: #444; }
.news_wrap h2 a:hover{ color: #769967; text-decoration: none; }

.app-con{ padding: 0 !important; background: url('/images/bg-app.jpg') center bottom no-repeat; background-size: auto 100%; overflow: hidden; }
.app_wrap{ float: left; width: 50%; padding:60px 60px; color: #fff; background: rgba(82, 115, 69, .85); }
.app_wrap:first-child{ background: none; }
.app_wrap h3{ float: left; margin: 0 0 50px 0; padding:0 0 10px 0; font-size: 30px; letter-spacing: 4px; background: url('/images/h3-border_bottome_white.gif') center bottom no-repeat; }
.app_wrap p{ max-width: 546px; font-size: 22px; line-height: 1.5em; text-align: justify; }
.app_wrap .transparent-btn{ margin-top: 23px; }

.transparent-btn a{ font-size: 22px !important; border-color: #fff !important; border-radius: 5px; color: #fff !important; }
.transparent-btn a:hover{border-color: #946134 !important; background: #946134 !important; }

.plan-con h3{ color: #769967; margin: 0 0 50px 0; padding:0 0 10px 0; font-size: 30px; font-weight: bold; letter-spacing: 4px; background: url('/images/h3-border_bottome_green.gif') center bottom no-repeat; text-align: center;}
.plan-con.padding-top{ padding-top:100px; }
.plan-con.padding-bottom{ padding-bottom:100px; }

.aside01{background: url('/images/service-01.png') center center no-repeat; background-size: cover;}
.aside02{background: url('/images/service-02.png') center center no-repeat; background-size: cover;}
.aside03{background: url('/images/service-03.png') center center no-repeat; background-size: cover;}

.services{padding:20px 20px 10px 20px !important;}
.services-box{ border-radius: 5px; position: relative; }
.services-box h4{ width: 100%; font-size: 26px !important; letter-spacing: 2px; color: #fff; position: absolute; top: 80px; text-align: center; }
.services-box img{ width: 100%; height: auto; }
.services-box .dk{ display: block; }
.services-box .mb{ display: none; }

.info-con{ padding: 60px 20px; background: url('/images/bg-info.png') center center no-repeat; background-size: auto 100%; }
.info-con h3{ color: #333; margin: 0 0 50px 0; padding:0 0 10px 0; font-size: 30px; font-weight: bold; line-height: 1.5em; letter-spacing: 4px; background: url('/images/h3-border_bottome_green.gif') center bottom no-repeat; text-align: center;}
.info-con h3 br{ display: none; }
.info_wrap{ max-width: 450px; margin: 0 auto; padding: 0 20px; }
.info{ overflow: hidden; font-size: 20px; line-height: 1.5em; }
.info span{ float: left; width: 100px; padding-left: 33px; color: #7b6244; text-align: left; }
.info span.adds{ background: url('/images/icon-adds.png') 0 center no-repeat; background-size: auto 24px; }
.info span.tel{ background: url('/images/icon-tel.png') 0 center no-repeat; background-size: auto 24px; }
.info span.fax{ background: url('/images/icon-fax.png') 0 center no-repeat; background-size: auto 24px; }
.info span.mail{ background: url('/images/icon-mail.png') 0 center no-repeat; background-size: auto 24px; }
.info p{ float: left; width: calc(100% - 120px); color: #000; text-align: left; }
.info_wrap .transparent-btn a{ margin-top: 23px; border-color: #946134 !important; background: #946134 !important; }

.support{ padding: 60px 0; background: #fff; text-align: center; }
.support .ad{ margin: 5px 10px; display: inline-block; }
.support .ad img{}

.cnt-con{ text-align: justify;}
.cnt-con h3{ color: #333; margin: 0 0 50px 0; padding:0 0 10px 0; font-size: 30px; font-weight: bold; letter-spacing: 4px; background: url('/images/h3-border_bottome_green.gif') center bottom no-repeat; text-align: center;}
.cnt-con h4{ color: #333; margin: 0 0 20px 0; padding:0 0 0 40px; font-size: 26px; font-weight: bold; letter-spacing: 4px; background: url('/images/icon-h3.png') 0 0 no-repeat; background-size: auto 28px;}
.cnt-con .container{ }
.cnt-con .intro_wrap{ margin: 0 -3px 100px -3px; border-radius: 5px; position: relative; background: #769967; }
.cnt-con .intro{ min-height: 160px; overflow: hidden; }
.intro_wrap .in_img{ position: absolute; top: -4px; left: -4px; }
.intro .in_l{ float: left; width: 340px;}
.intro .in_r{ float: left; width: calc(100% - 340px); padding: 40px 60px 40px 0; }
.intro .in_r p{ color:#fff; font-size: 20px; line-height: 1.5em; }

.flex{ width: 100%; display:inline-flex;}
.intro_wrap .in_l2{ width: calc(100% - 370px); padding:25px 30px;}
.intro_wrap .in_l2 p{ color:#fff; font-size: 20px; line-height: 1.5em; }
.intro_wrap .in_r2{ width: 370px; background: url('/images/pic-about01.jpg') right top no-repeat; background-size: auto 100%; border-top-right-radius: 5px; border-bottom-right-radius: 5px;}

.float_r{ float: right; margin:0 0 20px 20px; }

.txt-con.container{ max-width: 1070px; padding: 0 5px; }
.txt-con p, .txt-con ul.list01 li{ font-size: 20px; line-height: 1.5em; }
.txt-con span{ font-size: 30px; font-weight: bold; color: #769967; }

.txt-con .sec_wrap{ overflow: hidden; }
.txt-con .sec_wrap span{ float: left; width: 80px; font-size: 20px; font-weight: normal; line-height: 1.5em; color: #000; }
.txt-con .sec_wrap p{ float: left; width: calc(100% - 80px); line-height: 1.5em; }
.txt-con .sec_wrap.type2 span{ width: 120px; }
.txt-con .sec_wrap.type2 p{ width: calc(100% - 120px); }
.txt-con .sec_wrap.type3 span{ width: 140px; }
.txt-con .sec_wrap.type3 p{ width: calc(100% - 140px); }
.txt-con .table01{ float: left; width: 50%; font-size: 20px;}
.txt-con .table01 th, .txt-con .table01 td{ padding: 5px; line-height: 1.5em; background: #f4f4f4;}
.txt-con .table01 th{ font-weight: normal; color: #fff; background: #769967; }
.txt-con .table01 .td02{ height: 205px; }
.txt-con .table01 .td03{ height: 123px; }
.txt-con .table02{ float: left; width: 33.3333%; font-size: 20px;}
.txt-con .table02 th, .txt-con .table02 td{ padding: 5px; line-height: 1.5em; background: #f4f4f4;}
.txt-con .table02 th{ font-weight: normal; color: #fff; background: #769967; }
.txt-con ul.list01{ margin: 0; padding: 0; list-style: decimal; }
.txt-con ul.list01 li{ margin: 0 0 10px 17px;}
.txt-con { text-align: justify; }

.footer-con{ padding: 20px; background: #e2e2e2 !important; color: #333; }

/* Download Table Style */
.download-table tbody tr td:nth-child(2) {
    text-align: left;
    padding-left: 10px;
}

.download-box {
    width: 90%;
    max-width: 800px;
    margin: 100px auto;
}
.download-logo {
    text-align: center;
    margin-bottom: 70px;
}

.form-download {
    margin: 0 auto;
    max-width: 600px;
}
.download-input {
    height: 46px;
    border-radius: 0;
    border: 1px solid #769967;
}
.btn-download {
    width: 100%;
    max-width: 100%;
    font-size: 17px;
    border-radius: 0;
    padding: 10px 50px;
    background-color: #769967;
    color: #fff;
}
.btn-download:hover {
    background-color: #333;
    color: #fff;
}
@media (max-width: 767px) {
    .download-table {
        width: 95%;
    }
    .download-box {
        margin: 50px auto;
    }
    .download-logo {
        max-width: 90%;
        margin: 0 auto 50px;
    }
}

.sub-banner{ position: relative; }
.sub-banner i{ font-style: normal; }
.sub-banner br{display: none;}
.sub-banner img{ position: absolute; top: 45px; left: -110px; }
.sub-banner h2{ font-family: '微軟正黑體',sans-serif; letter-spacing: 4px; text-align: left; }
.sub-banner p{ font-family: '微軟正黑體',sans-serif; letter-spacing: 2px; font-size: 1.98em; line-height: 1.5em; text-align: left; }

.introduce{ max-width: 768px; margin: 0 auto 80px auto; padding: 15px; font-size: 20px; line-height: 1.5em; position: relative; text-align: center; }
.introduce h3{ color: #5d5d5d; margin: 0 0 10px 0; padding:0 0 10px 0; font-size: 30px; font-weight: normal; letter-spacing: 4px; background: none !important; text-align: center;}}
.introduce i{}
.introduce i.symbol01{ width: 16px; height: 12px; display: block; position: absolute; top: 0; left: 0; background: url('/images/icon-symbol01.png') 0 0 no-repeat; }
.introduce i.symbol02{ width: 16px; height: 12px; display: block; position: absolute; bottom: 0; right: 0; background: url('/images/icon-symbol02.png') 0 0 no-repeat;}

#catalog{ height: 500px; margin: 0 0 0px 0; padding: 0; list-style: none; }
#catalog li{ float: left; height: 100%; }
#catalog li:nth-child(1){ width: 24%; }
#catalog li:nth-child(2){ width: 38%; }
#catalog li:nth-child(3){ width: 38%; }
.catalog_wrap{ height: 100%; position: relative; }
.catalog_wrap2{ height: 50%; position: relative; }
.cw01{ height: calc(100% - 20px); margin:10px 0; background: url('/images/cw-01v.png') center center no-repeat; background-size: cover; border-radius: 5px; }
.cw02{ height: calc(100% - 20px); margin: 10px 0 10px 20px; background: url('/images/cw-02.png') left center no-repeat; background-size: cover; border-radius: 5px; }
.cw03{ height: calc(100% - 20px); margin: 0px 0 0px 20px; background: url('/images/cw-03.png') left center no-repeat; background-size: cover; border-radius: 5px; }
.cw04{ height: calc(100% - 20px); margin: 10px 0 10px 20px; background: url('/images/cw-04.png') left center no-repeat; background-size: cover; border-radius: 5px; }
.cw05{ height: calc(100% - 20px); margin: 0px 0 0px 20px; background: url('/images/cw-05.png') left center no-repeat; background-size: cover; border-radius: 5px; }
.catalog_wrap h4{ width: 100%; margin: 0; padding:70px 0 45px 0; color: #fff; font-size: 20px; letter-spacing: 4px; line-height: 1.3em; background: none; background: url('/images/arrow-left.png') center bottom no-repeat; background-size: auto 30px; text-align: center;}
.catalog_wrap2 h4{ width: 47%; margin: 0; padding:70px 0 45px 0; color: #fff; font-size: 20px; letter-spacing: 4px; line-height: 1.3em; background: none; background: url('/images/arrow-left.png') center bottom no-repeat; background-size: auto 30px; text-align: center;}
#catalog li a:hover{ text-decoration: none; }

#page_nav{ max-width: 900px; margin: 0 auto 100px auto; border: 1px solid #ccc; border-left: 0; border-right: 0; overflow: hidden; }
#page_nav.pli2 li{ float: left; width: 50%; font-size: 18px; font-weight: bold; text-align: center; }
#page_nav.pli3 li{ float: left; width: 33.3333%; font-size: 18px; font-weight: bold; text-align: center; }
#page_nav.pli4 li{ float: left; width: 25%; font-size: 18px; font-weight: bold; text-align: center; }
#page_nav.pli5 li{ float: left; width: 20%; font-size: 18px; font-weight: bold; text-align: center; }
#page_nav.pli6 li{ float: left; width: 16.6666%; font-size: 18px; font-weight: bold; text-align: center; }
#page_nav li a{ text-decoration: none;}
#page_nav li a p{ width: 100%; margin: 0; padding: 10px; color: #000; background: #eee; }
#page_nav li a:hover p, #page_nav li a.active p{ color: #fff; background: #946134; }

.faq_wrap{ margin-bottom: 20px; }
.faq_wrap h3{ margin: 0; padding:12px 20px 12px 80px; font-size: 26px; font-weight: bold; color: #fff; background:#769967 url('/images/icon-question.png') left 20px center no-repeat; background-size: auto 40px; position: relative; text-align: left; }
.faq_wrap h3 i{ font-size: 26px; font-weight: bold; position: absolute; left: 80px; }
.faq_quote{ padding: 25px 30px; background: #f5f5f5; overflow: hidden; }
.faq_quote p{ font-size: 20px; line-height: 1.5em; }
.faq_quote a span{ margin-top: 10px; float: right; color: #946134; font-size: 20px; display: block; }

.btn_back a{ width: 134px; height: 43px; margin: 30px auto 0 auto; font-size: 18px; font-weight: bold; line-height: 43px; text-align: center; color: #946134; background: #fff; border: 1px solid #946134; border-radius: 5px; display: block; }
.btn_back a:hover { color: #fff; background: #946134; text-decoration: none; }

.otw_portfolio_manager-portfolio-newspaper{ overflow: visible !important; }

.otw_portfolio_manager-portfolio-title { text-align: center;}

.otw_gap{ height: 1px; }
.otw{ margin: 0 20px; padding: 10px; font-size: 16px; font-weight: bold; color: #fff; background: #769967; clear: both; }
.otw_wrap{ max-width: 960px; margin: 50px auto 0 auto !important; padding: 0; list-style: none; overflow: hidden;}
.otw_wrap li{ float: left; width: 25%; }
.otw_wrap li a{ font-size: 18px; color: #666; text-decoration: none; }
.otw_wrap .otw_box{ margin: 10px 20px; padding: 8px; text-align: center; border-bottom: 1px solid #ccc;}


.otw_portfolio_manager-portfolio-newspaper-item{padding-top: 10px !important; }
.otw_portfolio_manager-portfolio-full{ margin: 0 0 20px 0!important;  }
.otw_portfolio_manager-portfolio-title-wrapper{ margin: 0 !important; padding-bottom: 5px;}
.otw_portfolio_manager-portfolio-title a:hover{ color: #946134; text-decoration: none; }

.member_wrap p{ padding-bottom: 11px !important; border-bottom: 1px dotted #ccc; }
.member_wrap p, .member_info p{ padding: 0 20px; font-size: 20px; line-height: 1.5em; }
.member_info h5{ padding: 10px 20px; font-size: 18px; font-weight: bold; color: #fff; background: #769967; }

.history01 { width: 500px; margin-left: 20px; }

.dk_show{ display: block; }
.mb_show{ display: none; }
/*** /EDIT ***/