/* ===================================
    Portfolio Filter
====================================== */
a img{border:none}
a{text-decoration:none;line-height:inherit;-webkit-transition:all .2s ease-out;-moz-transition:all .2s ease-out;-o-transition:all .2s ease-out;-ms-transition:all .2s ease-out;transition:all .2s ease-out}
p a,p a:visited{line-height:inherit}
.clear{clear:both;display:block;overflow:hidden;visibility:hidden;width:0;height:0}
.clearfix:before,.clearfix:after{content:"\0020";display:block;height:0;overflow:hidden}
.clearfix:after{clear:both}
.clearfix{zoom:1}
.flex-video{position:relative;padding-top:25px;padding-bottom:67.5%;height:0;overflow:hidden}
.flex-video.widescreen{padding-bottom:56.25%}
.flex-video.vimeo{padding-top:0}
.flex-video iframe,.flex-video object,.flex-video embed,.flex-video video{position:absolute;top:0;left:0;width:100%;height:100%}
.otw_circle{-webkit-border-radius:100%;-moz-border-radius:100%;border-radius:100%}
.otw_full-width,.otw_full-width .otw-row{width:auto!important}
.otw_portfolio_manager-mb0{margin-bottom:0!important}
.otw_portfolio_manager-mb5{margin-bottom:5px!important}
.otw_portfolio_manager-mb10{margin-bottom:10px!important}
.otw_portfolio_manager-mb15{margin-bottom:15px!important}
.otw_portfolio_manager-mb20{margin-bottom:20px!important}
.otw_portfolio_manager-mb25{margin-bottom:25px!important}
.otw_portfolio_manager-mb30{margin-bottom:30px!important}
.otw_portfolio_manager-mb35{margin-bottom:35px!important}
.otw_portfolio_manager-mb40{margin-bottom:40px!important}
.otw_portfolio_manager-mb45{margin-bottom:45px!important}
.otw_portfolio_manager-mb50{margin-bottom:50px!important}
.otw_portfolio_manager-portfolio-list-title-wrapper{margin-bottom:30px}
.otw_portfolio_manager-portfolio-item-holder{font-size:13px}
.without-space{margin-left:0!important;margin-right:0!important}
.without-space .otw-column,.without-space .otw-columns{padding-left:0!important;padding-right:0!important}
.otw_portfolio_manager-portfolio-full{position:relative;margin-bottom:25px}
.otw_portfolio_manager-portfolio-full.with-bg{background-color:#f5f5f5;margin-bottom:20px;padding:15px}
.otw_portfolio_manager-portfolio-full.with-bg .otw_portfolio_manager-portfolio-media{margin:-15px -15px 15px}
.otw_portfolio_manager-portfolio-full.with-border{border:1px solid #ebeaea;margin-bottom:20px;padding:15px 15px 0}
.otw_portfolio_manager-portfolio-full.with-border .otw_portfolio_manager-portfolio-media{margin:-16px -16px 15px}
.otw_portfolio_manager-image-left .otw_portfolio_manager-portfolio-full.with-bg .otw_portfolio_manager-portfolio-media,.otw_portfolio_manager-image-left .otw_portfolio_manager-portfolio-full.with-border .otw_portfolio_manager-portfolio-media,.otw_portfolio_manager-image-right .otw_portfolio_manager-portfolio-full.with-bg .otw_portfolio_manager-portfolio-media,.otw_portfolio_manager-image-right .otw_portfolio_manager-portfolio-full.with-border .otw_portfolio_manager-portfolio-media{margin:0!important}
.otw_portfolio_manager-image-left .otw_portfolio_manager-portfolio-full.with-border,.otw_portfolio_manager-image-right .otw_portfolio_manager-portfolio-full.with-border{padding:15px!important}
.otw_portfolio_manager-portfolio-full.only-media{margin-bottom:20px}
.without-space .otw_portfolio_manager-portfolio-full.only-media{margin-bottom:0}
.otw_portfolio_manager-image-left .otw_portfolio_manager-portfolio-full .otw-columns:first-child{float:left}
.otw_portfolio_manager-image-left .otw_portfolio_manager-portfolio-full.with-bg .otw-columns:first-child{margin:-15px -5px;padding-left:0;padding-right:5px}
.otw_portfolio_manager-image-left .otw_portfolio_manager-portfolio-full.with-border .otw-columns:first-child{margin:-16px -6px;padding-left:0;padding-right:4px}
.otw_portfolio_manager-image-right .otw_portfolio_manager-portfolio-full .otw-columns:first-child{float:right}
.otw_portfolio_manager-image-right .otw_portfolio_manager-portfolio-full.with-bg .otw-columns:first-child + .otw-columns{padding-left:1px;padding-right:15px}
.otw_portfolio_manager-image-right .otw_portfolio_manager-portfolio-full.with-bg .otw-columns:first-child{margin:-15px -5px;padding-left:5px;padding-right:0}
.otw_portfolio_manager-image-right .otw_portfolio_manager-portfolio-full.with-border .otw-columns:first-child + .otw-columns{padding-left:0;padding-right:15px}
.otw_portfolio_manager-image-right .otw_portfolio_manager-portfolio-full.with-border .otw-columns:first-child{margin:-16px -6px;padding-left:4px;padding-right:0}
.otw_portfolio_manager-image-left .otw_portfolio_manager-portfolio-full,.otw_portfolio_manager-image-right .otw_portfolio_manager-portfolio-full{margin-bottom:25px}
.otw_portfolio_manager-portfolio-media{position:relative;margin:0 0 15px}
.only-media .otw_portfolio_manager-portfolio-media{margin-bottom:0}
.only-media .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image > a,.only-media .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image > a > img{display:block!important;margin:0!important}
.only-media .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image > a{height:100%;width:100%}
.otw_portfolio_manager-image-left .otw_portfolio_manager-portfolio-media,.otw_portfolio_manager-image-right .otw_portfolio_manager-portfolio-media{margin-bottom:0}
.otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image > a,.otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image > a > img{position:relative;display:block;-webkit-transform:translateZ(0)}
@media only screen and (max-width: 767px) {
  .otw_portfolio_manager-portfolio-full:not(.only-media) .otw_portfolio_manager-format-image{display:inline-block}
  .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image > a,.otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image > a > img{width:100%}
  .otw_portfolio_manager-image-left .otw_portfolio_manager-portfolio-full .otw-columns:first-child,.otw_portfolio_manager-image-right .otw_portfolio_manager-portfolio-full .otw-columns:first-child{float:none;margin-bottom:20px!important}
  .otw_portfolio_manager-image-left .otw_portfolio_manager-portfolio-full.with-bg .otw-columns:first-child,.otw_portfolio_manager-image-right .otw_portfolio_manager-portfolio-full.with-bg .otw-columns:first-child,.otw_portfolio_manager-image-left .otw_portfolio_manager-portfolio-full.with-border .otw-columns:first-child,.otw_portfolio_manager-image-right .otw_portfolio_manager-portfolio-full.with-border .otw-columns:first-child{padding-left:0!important;padding-right:0!important}
}
.otw_portfolio_manager-portfolio-type{float:left;font-size:20px;width:40px;height:40px;text-align:center;background:#333;color:#fff;position:absolute;top:5px;right:5px;z-index:999;-webkit-transition:all .2s ease-out;-moz-transition:all .2s ease-out;-o-transition:all .2s ease-out;-ms-transition:all .2s ease-out;transition:all .2s ease-out}
.otw_portfolio_manager-portfolio-type:hover{background:#eee;color:#000}
.otw_portfolio_manager-portfolio-type i{display:block;line-height:40px}
.otw_portfolio_manager-portfolio-type i:before{display:block!important}
.otw_portfolio_manager-portfolio-image-left .otw_portfolio_manager-portfolio-type{left:15px!important;right:auto}
@media only screen and (max-width: 767px) {
  .otw_portfolio_manager-portfolio-type{display:none}
}
.otw_portfolio_manager-portfolio-title-wrapper{margin-bottom:10px}
.otw_portfolio_manager-portfolio-title{font-weight:400;font-size:18px;line-height:1.28em;word-wrap:break-word;margin:0}
.otw_portfolio_manager-portfolio-title a{color:inherit}
.otw_portfolio_manager-portfolio-meta-wrapper{font-size:11px;line-height:16px;color:#b6b6b6;margin-bottom:10px}
.otw_portfolio_manager-portfolio-meta-wrapper.few-lines{margin-bottom:15px}
.otw_portfolio_manager-portfolio-meta-wrapper.few-lines .head{float:left}
.otw_portfolio_manager-portfolio-meta-wrapper a{color:inherit}
.otw_portfolio_manager-portfolio-meta-item{display:inline-block;border:0;padding:0 10px 0 0;margin-bottom:5px;line-height:normal;text-align:left}
.otw_portfolio_manager-portfolio-meta-item:last-child{padding:0!important}
.otw_portfolio_manager-portfolio-meta-wrapper.few-lines .otw_portfolio_manager-portfolio-meta-item{display:block;border:0;padding:5px 0!important;margin-bottom:0;float:none;border-bottom:1px solid #ebeaea;text-align:right}
.otw_portfolio_manager-portfolio-meta-item a{display:inline-block!important}
.otw_portfolio_manager-portfolio-meta-wrapper .head{font-weight:300;margin-right:3px;color:#888}
.otw_portfolio_manager-project-info-box{clear:both;overflow:hidden;padding-bottom:15px;margin-bottom:15px;border-bottom:1px solid #ebeaea}
.otw_portfolio_manager-project-info-box h4{float:left;width:50%;margin:0}
.otw_portfolio_manager-project-info-box-content{float:left;width:50%}
.otw_portfolio_manager-project-info-box-content a{display:inline-block;margin-bottom:5px;color:#474747}
.otw_portfolio_manager-portfolio-content p{margin:0 0 15px}
.otw_portfolio_manager-portfolio-content p:last-child{margin:0}
.otw_portfolio_manager-portfolio-continue-reading{font-style:italic;display:inline-block;margin:0 0 15px}
.otw_portfolio_manager-portfolio-delimiter{clear:both;border-bottom:1px solid #ebeaea}
.with-bg .otw_portfolio_manager-portfolio-delimiter{border-bottom:1px solid #ddd}
.otw_portfolio_manager-portfolio-newspaper-filter,.otw_portfolio_manager-portfolio-newspaper-sort{position:relative;margin:10px 0}
.otw_portfolio_manager-portfolio-newspaper-filter ul,.otw_portfolio_manager-portfolio-newspaper-sort ul{margin:0;padding:0}
.otw_portfolio_manager-portfolio-newspaper-filter li,.otw_portfolio_manager-portfolio-newspaper-sort li{padding:0;margin:0;display:inline-block;vertical-align:top}
.otw_portfolio_manager-portfolio-newspaper-filter a,.otw_portfolio_manager-portfolio-newspaper-sort a{font-size:14px;color:#666;display:inline-block;padding:0 22px;line-height:1em;text-decoration:none}
.otw_portfolio_manager-portfolio-newspaper-filter a:hover,.otw_portfolio_manager-portfolio-newspaper-filter a.selected,.otw_portfolio_manager-portfolio-newspaper-sort a:hover,.otw_portfolio_manager-portfolio-newspaper-sort a.selected{color:#efaa15}
.otw_portfolio_manager-hover-effect-1 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image,.otw_portfolio_manager-hover-effect-2 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image{margin-bottom:0}
.otw_portfolio_manager-portfolio-overlay{opacity:0;position:absolute;left:0;right:0;top:0;bottom:0;background:rgba(67,31,9,0.9);text-align:center;-webkit-transition:all .3s ease-out;-moz-transition:all .3s ease-out;-o-transition:all .3s ease-out;-ms-transition:all .3s ease-out;transition:all .3s ease-out}
.otw_portfolio_manager-portfolio-text h3{color:#efaa15;font-size:30px;margin-top: 0}
.otw_portfolio_manager-portfolio-text h3 span{display:block;color:#fff;margin:10px 0 0;font-size:18px;font-family:'Poppins',sans-serif}
.ie8 .otw_portfolio_manager-portfolio-overlay{visibility:hidden}
.icon-link img,.icon-search img{height:auto!important;width:auto!important}
.otw_portfolio_manager-hover-effect-1 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw_portfolio_manager-portfolio-overlay,.otw_portfolio_manager-hover-effect-2 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw_portfolio_manager-portfolio-overlay,.otw_portfolio_manager-hover-effect-4 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw_portfolio_manager-portfolio-overlay,.otw_portfolio_manager-hover-effect-6 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw_portfolio_manager-portfolio-overlay,.otw_portfolio_manager-hover-effect-17 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw_portfolio_manager-portfolio-overlay{opacity:1;filter:alpha(100)}
.ie8 .otw_portfolio_manager-hover-effect-1 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw_portfolio_manager-portfolio-overlay,.ie8 .otw_portfolio_manager-hover-effect-2 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw_portfolio_manager-portfolio-overlay,.ie8 .otw_portfolio_manager-hover-effect-4 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw_portfolio_manager-portfolio-overlay,.ie8 .otw_portfolio_manager-hover-effect-6 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw_portfolio_manager-portfolio-overlay,.ie8 .otw_portfolio_manager-hover-effect-17 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw_portfolio_manager-portfolio-overlay{visibility:visible}
.otw_portfolio_manager-valign-middle,.otw_portfolio_manager-valign-middle{position:absolute;top:50%;width:100%}
.otw_portfolio_manager-hover-effect-1 .otw_portfolio_manager-portfolio-icon-wrapper,.otw_portfolio_manager-hover-effect-2 .otw_portfolio_manager-portfolio-icon-wrapper{text-align:center;padding-bottom:10px;overflow:hidden;-webkit-transition:all .4s ease;-moz-transition:all .4s ease;-o-transition:all .4s ease;-ms-transition:all .4s ease;transition:all .4s ease}
.otw_portfolio_manager-hover-effect-1 .otw_portfolio_manager-portfolio-icon-wrapper a,.otw_portfolio_manager-hover-effect-2 .otw_portfolio_manager-portfolio-icon-wrapper a,.otw_portfolio_manager-hover-effect-4 .otw_portfolio_manager-portfolio-icon-wrapper a,.otw_portfolio_manager-hover-effect-6 .otw_portfolio_manager-portfolio-icon-wrapper a{position:relative;display:inline-block!important;padding:1px 9px;width:45px!important;height:45px;line-height:45px;margin:0 2px;font-size:15px;color:#fff!important;text-align:center;vertical-align:middle;border:1px solid #efaa15}
.otw_portfolio_manager-hover-effect-2 .otw_portfolio_manager-portfolio-icon-wrapper a{left:20%}
.otw_portfolio_manager-hover-effect-2 .otw_portfolio_manager-portfolio-icon-wrapper a:first-child{left:-20%}
.otw_portfolio_manager-hover-effect-2 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw_portfolio_manager-portfolio-icon-wrapper a{left:0}
.otw_portfolio_manager-portfolio-icon-wrapper a:hover{opacity:.8;filter:alpha(80);-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=80)}
.otw_portfolio_manager-hover-effect-2 .otw_portfolio_manager-portfolio-text{position:relative;top:50px;-webkit-transition:all .4s ease;-moz-transition:all .4s ease;-o-transition:all .4s ease;-ms-transition:all .4s ease;transition:all .4s ease}
.otw_portfolio_manager-hover-effect-2 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw_portfolio_manager-portfolio-text{top:0}
.otw_portfolio_manager-hover-effect-1 .otw_portfolio_manager-portfolio-title,.otw_portfolio_manager-hover-effect-2 .otw_portfolio_manager-portfolio-title{font-size:16px;color:#fff;line-height:1.7em}
.otw_portfolio_manager-hover-effect-1 .otw_portfolio_manager-portfolio-meta-item,.otw_portfolio_manager-hover-effect-2 .otw_portfolio_manager-portfolio-meta-item,.otw_portfolio_manager-hover-effect-1 .otw_portfolio_manager-portfolio-meta-item a,.otw_portfolio_manager-hover-effect-2 .otw_portfolio_manager-portfolio-meta-item a{font-size:11px;color:#fff}
.otw-hover-effect{opacity:0;filter:alpha(0);-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0);position:absolute;left:0;right:0;top:0;bottom:0;background-color:#431f09;background-color:rgba(67,31,9,0.7);background-repeat:no-repeat;background-position:center;-webkit-transition:all .3s ease-out;-moz-transition:all .3s ease-out;-o-transition:all .3s ease-out;-ms-transition:all .3s ease-out;transition:all .3s ease-out;background-image:url(data:image/svg+xml,%3Csvg%20version=%221.1%22%20xmlns=%22http://www.w3.org/2000/svg%22%20xmlns:xlink=%22http://www.w3.org/1999/xlink%22%20x=%220px%22%20y=%220px%22%20width=%2233px%22%20height=%2233px%22%20viewBox=%220%200%2033%2033%22%20enable-background=%22new%200%200%2033%2033%22%20fill=%22white%22%20xml:space=%22preserve%22%3E%3Crect%20x=%2215%22%20width=%223%22%20height=%2233%22/%3E%3Crect%20y=%2215%22%20width=%2233%22%20height=%223%22/%3E%3C/svg%3E)}
.ie8 .otw-hover-effect{background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAN1wAADdcBQiibeAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAABTSURBVFiF7dYxDgAgCMBANP7/yzg10VUcGHq7pGGRkZlRcD4er0NmpeAXI2AEjIARMAJGoEXEivs7rnie02ITLSJWFC6i8LL6zAgYASNgBIyAEdhttgdCt+vzEgAAAABJRU5ErkJggg==)}
.otw_portfolio_manager-hover-effect-3 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw-hover-effect,.otw_portfolio_manager-hover-effect-5 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw-hover-effect,.otw_portfolio_manager-hover-effect-7 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw-hover-effect,.otw_portfolio_manager-hover-effect-8 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw-hover-effect,.otw_portfolio_manager-hover-effect-9 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw-hover-effect,.otw_portfolio_manager-hover-effect-10 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw-hover-effect,.otw_portfolio_manager-hover-effect-12 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw-hover-effect{opacity:1;filter:alpha(100);-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=70)}
.otw_portfolio_manager-hover-effect-4 .otw_portfolio_manager-portfolio-overlay{margin:10px;overflow:hidden;background-color:#431f09;background-color:rgba(67,31,9,0.7);transform:scale(0.5);-ms-transform:scale(0.5);-moz-transform:scale(0.5);-webkit-transform:scale(0.5);-o-transform:scale(0.5)}
.otw_portfolio_manager-hover-effect-4 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw_portfolio_manager-portfolio-overlay{transform:scale(1);-ms-transform:scale(1);-moz-transform:scale(1);-webkit-transform:scale(1);-o-transform:scale(1)}
.otw_portfolio_manager-hover-effect-4 .otw_portfolio_manager-portfolio-icon-wrapper{position:absolute;left:0;width:100%;top:50%;margin-top:-16px}
.otw_portfolio_manager-hover-effect-5 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image .otw-hover-effect{background:none!important}
.otw_portfolio_manager-hover-effect-5 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw-hover-effect{-webkit-box-shadow:0 0 0 5px #431f09 inset;-moz-box-shadow:0 0 0 5px #431f09 inset;box-shadow:0 0 0 5px #431f09 inset}
.ie8 .otw_portfolio_manager-hover-effect-5 .otw_portfolio_manager-portfolio-media:hover .otw-hover-effect{border:5px solid #431f09}
.otw_portfolio_manager-hover-effect-6 .otw_portfolio_manager-portfolio-overlay{background-color:transparent}
.otw_portfolio_manager-hover-effect-6 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover a img{opacity:.7;filter:alpha(70);-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=70)}
.otw_portfolio_manager-hover-effect-6 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw_portfolio_manager-portfolio-overlay{-webkit-box-shadow:0 0 25px #431f09 inset;-moz-box-shadow:0 0 25px #431f09 inset;box-shadow:0 0 25px #431f09 inset}
.otw_portfolio_manager-hover-effect-6 .otw_portfolio_manager-portfolio-icon-wrapper{position:absolute;left:0;width:100%;top:50%;margin-top:-16px}
.otw_portfolio_manager-hover-effect-7 .otw-hover-effect{background-position:center -50%;bottom:15%}
.otw_portfolio_manager-hover-effect-7 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw-hover-effect{background-position:center;bottom:0}
.otw_portfolio_manager-hover-effect-8 .otw-hover-effect{background-position:-50% center;right:15%}
.otw_portfolio_manager-hover-effect-8 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw-hover-effect{background-position:center;right:0}
.otw_portfolio_manager-hover-effect-9 .otw-hover-effect{background-position:center 150%;top:15%}
.otw_portfolio_manager-hover-effect-9 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw-hover-effect{background-position:center;top:0}
.otw_portfolio_manager-hover-effect-10 .otw-hover-effect{background-position:150% center;left:15%}
.otw_portfolio_manager-hover-effect-10 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw-hover-effect{background-position:center;left:0}
.otw_portfolio_manager-hover-effect-11 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image{overflow:hidden}
.otw_portfolio_manager-hover-effect-11 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image img{-webkit-transition-duration:.4s;-moz-transition-duration:.4s;-o-transition-duration:.4s;-ms-transition-duration:.4s;transition-duration:.4s}
.otw_portfolio_manager-hover-effect-11 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover img{transform:scale(1.3);-ms-transform:scale(1.3);-moz-transform:scale(1.3);-webkit-transform:scale(1.3);-o-transform:scale(1.3);opacity:.8;filter:alpha(80);-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=80)}
.otw_portfolio_manager-hover-effect-12 .otw-hover-effect{background:none}
.otw_portfolio_manager-hover-effect-12 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw-hover-effect{-webkit-box-shadow:0 0 7px #431f09;-moz-box-shadow:0 0 7px #431f09;box-shadow:0 0 7px #431f09}
.ie8 .otw_portfolio_manager-hover-effect-12 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw-hover-effect{outline:5px solid #efaa15}
.otw_portfolio_manager-hover-img{position:absolute;top:0!important;left:0!important;width:100%!important;height:100%!important;margin:0;padding:0;z-index:3!important;-webkit-transition:all .2s ease-out;-moz-transition:all .2s ease-out;-o-transition:all .2s ease-out;-ms-transition:all .2s ease-out;transition:all .2s ease-out}
.ie8 .otw_portfolio_manager-hover-img{display:none!important}
.otw_portfolio_manager-hover-effect-13 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image a,.otw_portfolio_manager-hover-effect-14 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image a,.otw_portfolio_manager-hover-effect-15 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image a,.otw_portfolio_manager-hover-effect-16 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image a{position:relative;overflow:hidden}
.otw_portfolio_manager-hover-effect-13 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image a .otw_portfolio_manager-hover-img,.otw_portfolio_manager-hover-effect-15 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image a .otw_portfolio_manager-hover-img{opacity:1;filter:alpha(100)}
.otw_portfolio_manager-hover-effect-13 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image a:hover .otw_portfolio_manager-hover-img,.otw_portfolio_manager-hover-effect-15 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image a:hover .otw_portfolio_manager-hover-img{opacity:0;filter:alpha(0)}
.otw_portfolio_manager-hover-effect-14 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image a .otw_portfolio_manager-hover-img,.otw_portfolio_manager-hover-effect-16 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image a .otw_portfolio_manager-hover-img{opacity:0;filter:alpha(0)}
.otw_portfolio_manager-hover-effect-14 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image a:hover .otw_portfolio_manager-hover-img,.otw_portfolio_manager-hover-effect-16 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image a:hover .otw_portfolio_manager-hover-img{opacity:1;filter:alpha(100)}
.ie8 .otw_portfolio_manager-hover-effect-13 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image a img{filter:gray}
.ie8 .otw_portfolio_manager-hover-effect-13 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image a img:hover{filter:none!important}
.ie8 .otw_portfolio_manager-hover-effect-14 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image a img{filter:none}
.ie8 .otw_portfolio_manager-hover-effect-14 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image a:hover img{filter:blur(3px)}
.ie8 .otw_portfolio_manager-hover-effect-15 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image a img{filter:blur(3px)}
.ie8 .otw_portfolio_manager-hover-effect-15 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image a:hover img{filter:none}
.ie8 .otw_portfolio_manager-hover-effect-16 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image a img{filter:none}
.ie8 .otw_portfolio_manager-hover-effect-16 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image a:hover img{filter:glow(color=white,strength=3)}
.otw_portfolio_manager-hover-effect-17 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image,.otw_portfolio_manager-hover-effect-19 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image{overflow:hidden}
.otw_portfolio_manager-hover-effect-17 .otw_portfolio_manager-portfolio-overlay{padding:20px;text-align:left;background-color:#fff;background-color:rgba(255,255,255,.9)!important}
.otw_portfolio_manager-hover-effect-18 .otw_portfolio_manager-portfolio-overlay,.otw_portfolio_manager-hover-effect-19 .otw_portfolio_manager-portfolio-overlay{opacity:1;filter:alpha(100);text-align:left;overflow:hidden;background-color:transparent!important}
.otw_portfolio_manager-hover-effect-18 .otw_portfolio_manager-portfolio-overlay .otw_portfolio_manager-portfolio-title-with-meta{position:absolute;bottom:0;width:100%;padding:15px 15px 10px;background-color:#fff;background-color:rgba(255,255,255,.9);-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=90);-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;-webkit-transition:all .2s ease-out;-moz-transition:all .2s ease-out;-o-transition:all .2s ease-out;-ms-transition:all .2s ease-out;transition:all .2s ease-out}
.otw_portfolio_manager-hover-effect-18 .otw_portfolio_manager-portfolio-overlay .otw_portfolio_manager-portfolio-title-with-meta .otw_portfolio_manager-portfolio-meta-wrapper,.otw_portfolio_manager-hover-effect-19 .otw_portfolio_manager-portfolio-overlay .otw_portfolio_manager-portfolio-title-with-meta .otw_portfolio_manager-portfolio-meta-wrapper{margin:0}
.otw_portfolio_manager-hover-effect-18 .otw_portfolio_manager-portfolio-overlay .otw_portfolio_manager-portfolio-content{position:absolute;bottom:-87px;height:87px;width:100%;padding:15px;background-color:#000;background-color:rgba(0,0,0,.8);-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=80);-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;-webkit-transition:all .2s ease-out;-moz-transition:all .2s ease-out;-o-transition:all .2s ease-out;-ms-transition:all .2s ease-out;transition:all .2s ease-out}
.otw_portfolio_manager-hover-effect-18 .otw_portfolio_manager-portfolio-overlay .otw_portfolio_manager-portfolio-content p,.otw_portfolio_manager-hover-effect-19 .otw_portfolio_manager-portfolio-overlay .otw_portfolio_manager-portfolio-content p{color:#fff;margin:0;max-height:57px;overflow:hidden}
.otw_portfolio_manager-hover-effect-18 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw_portfolio_manager-portfolio-overlay .otw_portfolio_manager-portfolio-title-with-meta{bottom:87px}
.otw_portfolio_manager-hover-effect-18 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw_portfolio_manager-portfolio-overlay .otw_portfolio_manager-portfolio-content{bottom:0}
.otw_portfolio_manager-hover-effect-19 .otw_portfolio_manager-portfolio-overlay{top:auto;bottom:-100%}
.otw_portfolio_manager-hover-effect-19 .otw_portfolio_manager-portfolio-overlay .otw_portfolio_manager-portfolio-title-with-meta{padding:15px 15px 10px;background-color:#fff;background-color:rgba(255,255,255,.9);-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=90);-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box}
.otw_portfolio_manager-hover-effect-19 .otw_portfolio_manager-portfolio-overlay .otw_portfolio_manager-portfolio-content{padding:15px;background-color:#000;background-color:rgba(0,0,0,.8);-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=80)}
.otw_portfolio_manager-hover-effect-19 .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image:hover .otw_portfolio_manager-portfolio-overlay{bottom:0}
.otw_portfolio_manager-portfolio-timeline{position:relative;overflow:hidden}
.otw_portfolio_manager-portfolio-timeline:after{content:"";position:absolute;top:0;bottom:0;left:50%;margin-left:-1px;width:2px;height:100%;background-color:#ebeaea;z-index:-1}
.otw_portfolio_manager-portfolio-timeline-heading{position:relative;height:80px;overflow:hidden}
.otw_portfolio_manager-portfolio-timeline-heading:before{content:"\f0e6";font-family:'FontAwesome';font-size:42px;color:#ebeaea;text-align:center;position:absolute;top:0;left:50%;margin-left:-25px;width:50px;height:40px;line-height:40px}
.otw_portfolio_manager-portfolio-timeline-heading:after{content:"";position:absolute;top:0;bottom:0;left:50%;margin-left:-1px;width:2px;height:100%;margin-top:40px;background-color:#ebeaea}
.otw_portfolio_manager-portfolio-timeline-item{position:relative}
.otw_portfolio_manager-portfolio-timeline-item .otw_portfolio_manager-timeline-title{font-size:13px;font-weight:400;width:110px;height:25px;line-height:25px;text-align:center;position:absolute;margin:0;color:#747474;background-color:#ebeaea}
.otw_portfolio_manager-portfolio-timeline-item.odd .otw_portfolio_manager-timeline-title{right:-55px}
.otw_portfolio_manager-portfolio-timeline-item.even .otw_portfolio_manager-timeline-title{left:-55px}
.otw_portfolio_manager-portfolio-timeline-item.odd{padding-right:80px!important;float:left!important;clear:left!important}
.otw_portfolio_manager-portfolio-timeline-item.odd:before,.otw_portfolio_manager-portfolio-timeline-item.even:before{content:"";position:absolute;top:30%;width:0;height:0;border:8px solid transparent}
.otw_portfolio_manager-portfolio-timeline-item.odd:before{right:64px;border-left-color:#ebeaea}
.otw_portfolio_manager-portfolio-timeline-item.even:before{left:64px;border-right-color:#ebeaea}
.otw_portfolio_manager-portfolio-timeline-item.odd:after,.otw_portfolio_manager-portfolio-timeline-item.even:after{content:"";position:absolute;top:30%;margin-top:3px;width:10px;height:10px;-webkit-border-radius:100%;-moz-border-radius:100%;border-radius:100%;background-color:#ebeaea}
.otw_portfolio_manager-portfolio-timeline-item.odd:after{right:-5px}
.otw_portfolio_manager-portfolio-timeline-item.even:after{left:-5px}
.otw_portfolio_manager-portfolio-timeline-item.even{padding-left:80px!important;float:right!important;clear:right!important}
@media only screen and (max-width: 767px) {
  .otw_portfolio_manager-portfolio-timeline:after,.otw_portfolio_manager-portfolio-timeline-heading,.otw_portfolio_manager-portfolio-timeline-item:before,.otw_portfolio_manager-portfolio-timeline-item:after,.otw_portfolio_manager-timeline-title{display:none!important}
  .otw_portfolio_manager-portfolio-timeline-item.odd{padding-right:10px!important;float:none!important;clear:none!important}
  .otw_portfolio_manager-portfolio-timeline-item.even{padding-left:10px!important;float:none!important;clear:none!important}
}
.otw_portfolio_manager-mosaic-layout{margin:-10px -10px 20px!important}
.otw_portfolio_manager-mosaic-layout.without-space{margin:0;margin-bottom:30px!important}
.otw_portfolio_manager-mosaic-layout .otw_portfolio_manager-iso-item{padding:10px!important;margin-bottom:0!important;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box}
.otw_portfolio_manager-mosaic-layout.without-space .otw_portfolio_manager-iso-item{padding:0!important}
.otw_portfolio_manager-mosaic-layout .otw_portfolio_manager-portfolio-full{overflow:hidden}
.otw_portfolio_manager-mosaic-layout .otw_portfolio_manager-portfolio-full.only-media{padding:0!important}
.otw_portfolio_manager-mosaic-layout .otw_portfolio_manager-portfolio-full.only-media .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image{margin:0!important}
.otw_portfolio_manager-pagination{*zoom:1;clear:both;float:right;font-size:11px;margin-bottom:25px}
.otw_portfolio_manager-pagination.hide{display:none}
.otw_portfolio_manager-pagination:before,.otw_portfolio_manager-pagination:after{content:" ";display:table}
.otw_portfolio_manager-pagination:after{clear:both}
.otw_portfolio_manager-pagination a.previouspostslink{font-size:0}
.otw_portfolio_manager-pagination a.previouspostslink:hover{color:#000}
.otw_portfolio_manager-pagination a.previouspostslink:before{content:"\F053\00a0\00a0";font-size:9px;font-family:"FontAwesome"}
.otw_portfolio_manager-pagination a.previouspostslink:after{font-size:11px;content:"Previous"}
.otw_portfolio_manager-pagination a.nextpostslink{font-size:0}
.otw_portfolio_manager-pagination a.nextpostslink:hover{color:#000}
.otw_portfolio_manager-pagination a.nextpostslink:before{font-size:11px;content:"Next"}
.otw_portfolio_manager-pagination a.nextpostslink:after{content:"\00a0\00a0\F054";font-size:9px;font-family:"FontAwesome"}
.otw_portfolio_manager-pagination a,.otw_portfolio_manager-pagination span{display:block;float:left;margin-left:4px;padding:4px 8px}
.otw_portfolio_manager-pagination a.page,.otw_portfolio_manager-pagination span{border:1px solid #eee}
.otw_portfolio_manager-pagination a.page:hover{border-color:#ebeaea;color:#000}
.otw_portfolio_manager-pagination span.current{border-color:#444;background:#888;color:#fff}
.otw_portfolio_manager-load-more{margin:15px 0 30px}
.otw_portfolio_manager-load-more a{font-size:16px;color:#333;display:block;padding:10px 15px;line-height:1em;background-color:#eee;text-align:center}
.otw_portfolio_manager-load-more a:hover{color:#fff;background-color:#333}
.otw_portfolio_manager-horizontal-layout-wrapper{overflow:hidden;margin-bottom:30px}
.otw_portfolio_manager-horizontal-layout-items{clear:both!important;display:block!important;padding:0;margin:0 -15px -4px 0 !important;overflow:hidden;opacity:0;filter:alpha(0);-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0);-webkit-transition:all .2s ease-out;-moz-transition:all .2s ease-out;-o-transition:all .2s ease-out;-ms-transition:all .2s ease-out;transition:all .2s ease-out}
.otw_portfolio_manager-horizontal-item{float:left;padding:0;margin-right:4px;margin-bottom:4px;-webkit-user-select:none}
.otw_portfolio_manager-horizontal-layout-items.without-space .otw_portfolio_manager-horizontal-item{margin-right:0;margin-bottom:0}
.otw_portfolio_manager-horizontal-item .otw_portfolio_manager-portfolio-full{overflow:hidden;margin-bottom:0!important}
.otw_portfolio_manager-horizontal-item .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image{overflow:hidden}
.otw_portfolio_manager-horizontal-item .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image > a{position:relative;width:100%;height:100%}
.otw_portfolio_manager-horizontal-item .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image img{min-width:100%;max-width:100%;min-height:100%;max-height:100%}
.otw_portfolio_manager-format-video iframe{border:0}
.otw_portfolio_manager-format-audio object{height:81px}
.otw_portfolio_manager-format-audio iframe{width:100%;height:166px;margin:0;padding:0;border:0;overflow:hidden}
.ie8 .otw_portfolio_manager-format-audio iframe{height:81px}
.otw_portfolio_manager-slider,.otw_portfolio_manager-format-gallery{position:relative;overflow:hidden}
.otw_portfolio_manager-slider.has-caption,.otw_portfolio_manager-format-gallery.has-caption{margin-bottom:30px}
.otw_portfolio_manager-slider.with-border{padding:3px;border:1px solid #ebeaea;background-color:#fff}
.otw_portfolio_manager-slider ul,.otw_portfolio_manager-format-gallery ul,.otw_portfolio_manager-slider ol,.otw_portfolio_manager-format-gallery ol{padding:0!important;margin:0;list-style:none!important}
.otw_portfolio_manager-slider .slides,.otw_portfolio_manager-format-gallery .slides{overflow:hidden;width:1000%}
.otw_portfolio_manager-slider .slides li,.otw_portfolio_manager-format-gallery .slides li{float:left;position:relative}
.otw_portfolio_manager-slider .slides a,.otw_portfolio_manager-slider .slides img,.otw_portfolio_manager-format-gallery .slides a,.otw_portfolio_manager-format-gallery .slides img{display:inline-block}
.otw_portfolio_manager-slider.otw_portfolio_manager-carousel .slides{margin:0;padding:0}
.otw_portfolio_manager-carousel .slides li{margin:0 20px 0 0}
.otw_portfolio_manager-carousel .otw_portfolio_manager-portfolio-media.otw_portfolio_manager-format-image{margin-bottom:0}
.otw_portfolio_manager-carousel .otw_portfolio_manager-portfolio-title,.otw_portfolio_manager-carousel .otw_portfolio_manager-portfolio-title a{font-size:17px;line-height:normal}
.otw_portfolio_manager-flex-caption{padding:20px 25px;width:100%;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;box-sizing:border-box}
.otw_portfolio_manager-flex-caption.with-bg{background:#000;background:rgba(0,0,0,0.7);-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=70);color:#fff}
.otw_portfolio_manager-slider.caption-left .otw_portfolio_manager-flex-caption{text-align:left!important}
.otw_portfolio_manager-slider.caption-center .otw_portfolio_manager-flex-caption{text-align:center!important}
.otw_portfolio_manager-slider.caption-right .otw_portfolio_manager-flex-caption{text-align:right!important}
.otw_portfolio_manager-caption-title,.otw_portfolio_manager-caption-title a{font-size:18px;color:#333;margin:0}
.otw_portfolio_manager-caption-title a{display:inline-block}
.otw_portfolio_manager-flex-caption.with-bg .otw_portfolio_manager-caption-title,.otw_portfolio_manager-flex-caption.with-bg .otw_portfolio_manager-caption-title a{color:#fff}
.otw_portfolio_manager-caption-title a:hover{color:#888}
.otw_portfolio_manager-caption-title + .otw_portfolio_manager-caption-excpert{margin:10px 0 0}
.otw_portfolio_manager-caption-excpert,.otw_portfolio_manager-caption-excpert p{margin:0;font-size:13px;color:#333}
.otw_portfolio_manager-flex-caption.with-bg .otw_portfolio_manager-caption-excpert{color:#fff}
@media only screen and (min-width: 600px) {
  .otw_portfolio_manager-flex-caption{position:absolute;bottom:0}
}
.otw_portfolio_manager-slider .flex-direction-nav,.otw_portfolio_manager-format-gallery .flex-direction-nav{position:absolute;top:50%;margin-top:-20px;left:0;width:100%;-webkit-transition-duration:.4s;-moz-transition-duration:.4s;-o-transition-duration:.4s;-ms-transition-duration:.4s;transition-duration:.4s;opacity:0;filter:alpha(0);-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0);height:0;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;box-sizing:border-box}
.ie8 .otw_portfolio_manager-slider .flex-direction-nav,.ie8 .otw_portfolio_manager-format-gallery .flex-direction-nav{height:auto}
.otw_portfolio_manager-widget-carousel .otw_portfolio_manager-carousel .flex-direction-nav{margin-top:-12px}
.otw_portfolio_manager-slider.with-border .flex-direction-nav{padding:0 3px!important}
.otw_portfolio_manager-slider:hover .flex-direction-nav,.otw_portfolio_manager-format-gallery:hover .flex-direction-nav{-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100);opacity:1}
.otw_portfolio_manager-slider .flex-direction-nav a,.otw_portfolio_manager-format-gallery .flex-direction-nav a{display:block;background-color:#000;background-position:center center;background-repeat:no-repeat;width:30px;height:40px;line-height:40px;-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=30);opacity:.3;outline:none;text-indent:-9999px}
.otw_portfolio_manager-slider .flex-direction-nav a:hover,.otw_portfolio_manager-format-gallery .flex-direction-nav a:hover{-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=50);opacity:.5}
.otw_portfolio_manager-slider .flex-direction-nav .flex-next,.otw_portfolio_manager-format-gallery .flex-direction-nav .flex-next{float:right;background-image:url(/images/arrow-next.png)}
.otw_portfolio_manager-slider .flex-direction-nav .flex-prev,.otw_portfolio_manager-format-gallery .flex-direction-nav .flex-prev{float:left;background-image:url(/images/arrow-prev.png)}
.otw_portfolio_manager-widget-carousel .otw_portfolio_manager-carousel .flex-direction-nav a{font-size:14px;color:#fff;width:14px;height:24px;line-height:24px;text-align:center;text-indent:0;background-image:none!important}
.otw_portfolio_manager-slider .flex-control-nav,.otw_portfolio_manager-format-gallery .flex-control-nav{position:absolute;width:100%;list-style:none;text-align:center;bottom:5px;left:0}
.otw_portfolio_manager-widget-carousel .otw_portfolio_manager-carousel .flex-control-nav{-webkit-transition-duration:.4s;-moz-transition-duration:.4s;-o-transition-duration:.4s;-ms-transition-duration:.4s;transition-duration:.4s;-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0);opacity:0}
.otw_portfolio_manager-widget-carousel .otw_portfolio_manager-carousel:hover .flex-control-nav{-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100);opacity:1}
.otw_portfolio_manager-slider.has-caption .flex-control-nav,.otw_portfolio_manager-format-gallery.has-caption .flex-control-nav{bottom:-25px!important}
.otw_portfolio_manager-slider.no-control-nav .flex-control-nav{display:none!important}
.otw_portfolio_manager-slider .flex-control-nav li,.otw_portfolio_manager-format-gallery .flex-control-nav li{display:inline-block;margin-right:5px}
.otw_portfolio_manager-slider .flex-control-nav li a,.otw_portfolio_manager-format-gallery .flex-control-nav li a{display:block;width:10px;height:10px;text-indent:-99999px;background:#000;overflow:hidden;-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=50);opacity:.5}
.otw_portfolio_manager-slider .flex-control-nav li a:hover,.otw_portfolio_manager-slider .flex-control-nav li a.flex-active,.otw_portfolio_manager-format-gallery .flex-control-nav li a:hover,.otw_portfolio_manager-format-gallery .flex-control-nav li a.flex-active{-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=80);opacity:.8;cursor:pointer}
.otw_portfolio_manager-sidebar > ul{list-style:none;padding:0;margin:0}
.otw_portfolio_manager-sidebar .widget{list-style:none;padding:0;margin:0 0 30px}
.otw_portfolio_manager-widget{margin-bottom:30px}
.otw_portfolio_manager-sidebar .widget-title{font-size:20px;margin:0 0 15px}
.otw_portfolio_manager-widget-portfolio-latest ul{list-style:none;padding:0;margin:0;font-size:13px}
.otw_portfolio_manager-widget-portfolio-latest > ul > li{display:block;margin-bottom:20px;min-height:60px}
.otw_portfolio_manager-widget-portfolio-latest li > a{display:inline-block}
.otw_portfolio_manager-widget-portfolio-latest li > a img{display:block}
.otw_portfolio_manager-widget-portfolio-latest li > a{position:relative}
.otw_portfolio_manager-widget-portfolio-latest li > a:after{content:'';position:absolute;left:0;top:0;width:100%;height:100%;-webkit-transition:all .2s ease-out;-moz-transition:all .2s ease-out;-o-transition:all .2s ease-out;-ms-transition:all .2s ease-out;transition:all .2s ease-out}
.otw_portfolio_manager-widget-portfolio-latest li > a:hover:after{background-color:#000;background-color:rgba(0,0,0,0.5)}
.otw_portfolio_manager-widget-portfolio-latest li > a:hover{-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=80)}
.otw_portfolio_manager-widget-portfolio-latest.left-image li > a{float:left;margin:0 10px 0 0}
.otw_portfolio_manager-widget-portfolio-latest.right-image li > a{float:right;margin:0 0 0 10px}
.otw_portfolio_manager-widget-portfolio-latest.top-image li > a{margin:0 0 10px;max-width:100%}
.otw_portfolio_manager-widget-portfolio-latest li > h3,.otw_portfolio_manager-widget-portfolio-latest li > h3 a{font-weight:700;font-size:15px;line-height:1.3em;margin:0}
.otw_portfolio_manager-widget-portfolio-latest li > p{font-size:12px;margin:10px 0 0}
.otw_portfolio_manager-widget-carousel .otw_portfolio_manager-caption-title,.otw_portfolio_manager-widget-carousel .otw_portfolio_manager-caption-title a{font-size:15px}
.otw_portfolio_manager-widget-carousel .otw_portfolio_manager-carousel .slides li{margin:0 10px 0 0 !important}
.otw_portfolio_manager-social-item{float:left;display:block;width:32px;height:32px;line-height:32px;text-align:center;background-color:#333;-webkit-transition:all .2s ease-out;-moz-transition:all .2s ease-out;-o-transition:all .2s ease-out;-ms-transition:all .2s ease-out;transition:all .2s ease-out;margin:0 10px 10px 0}
.otw_portfolio_manager-social-item:last-child{margin-right:0}
.otw_portfolio_manager-social-item i{color:#fff;font-size:16px;line-height:32px}
.otw_portfolio_manager-social-item:hover{background-color:#888}
.otw_portfolio_manager-social-share-buttons-wrapper{clear:both}
.otw_portfolio-manager-share-button-boxy{float:left;position:relative;height:61px;width:52px;margin:0 2px 15px 0}
.otw_portfolio-manager-share-button-boxy.fb-like{width:49px;overflow:hidden}
.otw_portfolio-manager-share-button-boxy.fb-like:before,.otw_portfolio-manager-share-button-boxy.fb-like:after{display:none!important}
.otw_portfolio-manager-share-button-boxy:before,.otw_portfolio-manager-share-button-boxy:after{content:"";position:absolute;top:30px;right:23px;height:0;width:0;border:3px solid transparent;border-top-color:#fff;z-index:3}
.otw_portfolio-manager-share-button-boxy:after{top:31px;border-color:transparent;border-top-color:#ccc;z-index:2}
.small-style .otw_portfolio-manager-share-button-boxy{height:22px;width:48px;margin:0 2px 15px 0}
.small-style .otw_portfolio-manager-share-button-boxy.fb-like{padding-top:2px;margin-bottom:13px;height:20px}
.small-style .otw_portfolio-manager-share-button-boxy:before,.small-style .otw_portfolio-manager-share-button-boxy:after{top:8px}
.small-style .otw_portfolio-manager-share-button-boxy:before{position:absolute;right:45px;border-color:transparent;border-right-color:#fff}
.small-style .otw_portfolio-manager-share-button-boxy:after{right:46px;border-color:transparent;border-right-color:#ccc}
.otw_portfolio-manager-share-button-boxy:last-child{margin-right:0!important}
.otw_portfolio-manager-social-share{position:relative;top:39px;display:inline-block;width:50px;height:20px;line-height:20px;font-size:11px;color:#555;text-align:center;text-decoration:none;border:1px solid #ccc;border-top-color:#e0e0e0;border-left-color:#e0e0e0;-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px;outline:none;-webkit-box-shadow:#f0f0f0 -1px -1px 0 0 inset;-moz-box-shadow:#f0f0f0 -1px -1px 0 0 inset;box-shadow:#f0f0f0 -1px -1px 0 0 inset;background:#fff;background:-moz-linear-gradient(top,#fff 0%,#e0e0e0 100%);background:-webkit-gradient(linear,left top,left bottom,color-stop(0%,#fff),color-stop(100%,#e0e0e0));background:-webkit-linear-gradient(top,#fff 0%,#e0e0e0 100%);background:-o-linear-gradient(top,#fff 0%,#e0e0e0 100%);background:-ms-linear-gradient(top,#fff 0%,#e0e0e0 100%);background:linear-gradient(to bottom,#fff 0%,#e0e0e0 100%);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff',endColorstr='#e0e0e0',GradientType=0);-webkit-transition:all .1s ease-in-out;-moz-transition:all .1s ease-in-out;-ms-transition:all .1s ease-in-out;-o-transition:all .1s ease-in-out;transition:all .1s ease-in-out}
.small-style .otw_portfolio-manager-social-share{top:0;width:40px}
.otw_portfolio-manager-social-share:hover{border-color:#bbb;text-decoration:none}
.otw_portfolio-manager-social-share span.data-shares{position:absolute;bottom:29px;left:-1px;right:0;top:auto;font-size:12px;color:#333;width:50px;height:29px;line-height:29px;-webkit-border-radius:2px;-moz-border-radius:2px;border-radius:2px;border:1px solid #ccc;background-color:#fff}
.small-style .otw_portfolio-manager-social-share span.data-shares{left:auto;right:-57px;bottom:auto;top:-1px;font-size:11px;width:44px;height:20px;line-height:20px}
.otw_portfolio-manager-social-share i{font-size:17px;line-height:21px}
.otw_portfolio-manager-social-share.facebook i{color:#3b579d}
.otw_portfolio-manager-social-share.twitter i{color:#42abf1}
.otw_portfolio-manager-social-share.google_plus i{color:#dd4c39}
.otw_portfolio-manager-social-share.linkedin i{color:#0073a4}
.otw_portfolio-manager-social-share.pinterest i{color:#bf1e25}
.otw_portfolio_manager-nav-single{padding:15px 0;border-top:1px solid #ebeaea;border-bottom:1px solid #ebeaea;margin-bottom:30px}
.otw_portfolio_manager-nav-single a{float:left;color:#4a4f51}
.otw_portfolio_manager-nav-single a.next{float:right}
.ui-helper-hidden{display:none}
.ui-helper-hidden-accessible{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}
.ui-helper-reset{margin:0;padding:0;border:0;outline:0;line-height:1.3;text-decoration:none;font-size:100%;list-style:none}
.ui-helper-clearfix:before,.ui-helper-clearfix:after{content:"";display:table;border-collapse:collapse}
.ui-helper-clearfix:after{clear:both}
.ui-helper-clearfix{min-height:0}
.ui-helper-zfix{width:100%;height:100%;top:0;left:0;position:absolute;opacity:0;filter:Alpha(Opacity=0);-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0)}
.ui-front{z-index:100}
.ui-state-disabled{cursor:default!important}
.ui-icon{display:block;text-indent:-99999px;overflow:hidden;background-repeat:no-repeat}
.ui-widget-overlay{position:fixed;top:0;left:0;width:100%;height:100%}
.ui-accordion .ui-accordion-header{display:block;cursor:pointer;position:relative;margin-top:2px;padding:.8em .5em .8em .8em;min-height:0}
.ui-accordion .ui-accordion-icons{padding-left:2.2em}
.ui-accordion .ui-accordion-noicons{padding-left:.7em}
.ui-accordion .ui-accordion-icons .ui-accordion-icons{padding-left:2.2em}
.ui-accordion .ui-accordion-header .ui-accordion-header-icon{position:absolute;left:.5em;top:50%;margin-top:-8px}
.ui-accordion .ui-accordion-content{padding:1.2em 2.2em;border-top:0;overflow:auto}
.ui-tabs{position:relative;padding:.2em}
.ui-tabs .ui-tabs-nav{margin:0;padding:0}
.ui-tabs .ui-tabs-nav li{list-style:none;float:left;position:relative;top:0;margin:1px .2em 0 0;border-bottom-width:0;padding:0;white-space:nowrap}
.ui-tabs .ui-tabs-nav li a{float:left;padding:.5em 1em;text-decoration:none}
.ui-tabs .ui-tabs-nav li.ui-tabs-active{margin-bottom:-1px}
.ui-tabs .ui-tabs-nav li.ui-tabs-active a,.ui-tabs .ui-tabs-nav li.ui-state-disabled a,.ui-tabs .ui-tabs-nav li.ui-tabs-loading a{cursor:text}
.ui-tabs .ui-tabs-nav li a,.ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-active a{cursor:pointer}
.ui-tabs .ui-tabs-panel{display:block;border-width:0;padding:1em 1.4em;background:none}
.otw_portfolio_manager-tabs.ui-tabs .ui-tabs-nav li{border:1px solid #ebeaea;margin-bottom:-1px}
.otw_portfolio_manager-tabs.ui-tabs .ui-tabs-nav li a{color:#474747;outline:none}
.otw_portfolio_manager-tabs.ui-tabs .ui-tabs-nav li.ui-tabs-active{border-bottom:1px solid #fff}
.otw_portfolio_manager-tabs.ui-tabs .ui-tabs-nav li.ui-tabs-active a{color:#efaa15}
.otw_portfolio_manager-tabs.ui-tabs .ui-tabs-panel{border:1px solid #ebeaea}
.otw_portfolio_manager-accordion.ui-accordion .ui-accordion-header{border:1px solid #ebeaea;color:#474747;margin-bottom:-1px;text-transform:uppercase;background-color:#fafafa}
.otw_portfolio_manager-accordion.ui-accordion .ui-accordion-header-active{color:#efaa15}
.otw_portfolio_manager-accordion.ui-accordion .ui-accordion-content{border:1px solid #ebeaea}
.fancybox-wrap,.fancybox-skin,.fancybox-outer,.fancybox-inner,.fancybox-image,.fancybox-wrap iframe,.fancybox-wrap object,.fancybox-nav,.fancybox-nav span,.fancybox-tmp{padding:0;margin:0;border:0;outline:0;vertical-align:top}
.fancybox-wrap{position:absolute;top:0;left:0;z-index:8020}
.fancybox-skin{position:relative;background:#f9f9f9;color:#444;text-shadow:none;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px}
.fancybox-opened{z-index:8030}
.fancybox-opened .fancybox-skin{-webkit-box-shadow:0 10px 25px rgba(0,0,0,.5);-moz-box-shadow:0 10px 25px rgba(0,0,0,.5);box-shadow:0 10px 25px rgba(0,0,0,.5)}
.fancybox-outer,.fancybox-inner{position:relative}
.fancybox-inner{overflow:hidden}
.fancybox-type-iframe .fancybox-inner{-webkit-overflow-scrolling:touch}
.fancybox-error{color:#444;font:14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;margin:0;padding:15px;white-space:nowrap}
.fancybox-image,.fancybox-iframe{display:block;width:100%;height:100%}
.fancybox-image{max-width:100%;max-height:100%}
#fancybox-loading,.fancybox-close,.fancybox-prev span,.fancybox-next span{background-image:url(/images/fancybox/fancybox_sprite.png)}
#fancybox-loading{position:fixed;top:50%;left:50%;margin-top:-22px;margin-left:-22px;background-position:0 -108px;-ms-filter:alpha(Opacity=80);opacity:.8;cursor:pointer;z-index:8060}
#fancybox-loading div{width:44px;height:44px;background:url(/images/fancybox/fancybox_loading.gif) center center no-repeat}
.fancybox-close{position:absolute;top:-18px;right:-18px;width:36px;height:36px;cursor:pointer;z-index:8040}
.fancybox-nav{position:absolute;top:0;width:40%;height:100%;cursor:pointer;text-decoration:none;background:transparent url(/images/fancybox/blank.gif);-webkit-tap-highlight-color:rgba(0,0,0,0);z-index:8040}
.fancybox-prev{left:0}
.fancybox-next{right:0}
.fancybox-nav span{position:absolute;top:50%;width:36px;height:34px;margin-top:-18px;cursor:pointer;z-index:8040;visibility:hidden}
.fancybox-prev span{left:10px;background-position:0 -36px}
.fancybox-next span{right:10px;background-position:0 -72px}
.fancybox-nav:hover span{visibility:visible}
.fancybox-tmp{position:absolute;top:-99999px;left:-99999px;visibility:hidden;max-width:99999px;max-height:99999px;overflow:visible!important}
.fancybox-lock{overflow:hidden!important;width:auto}
.fancybox-lock body{overflow:hidden!important}
.fancybox-lock-test{overflow-y:hidden!important}
.fancybox-overlay{position:absolute;top:0;left:0;overflow:hidden;display:none;z-index:8010;background:url(/images/fancybox/fancybox_overlay.png)}
.fancybox-overlay-fixed{position:fixed;bottom:0;right:0}
.fancybox-lock .fancybox-overlay{overflow:auto;overflow-y:scroll}
.fancybox-title{visibility:hidden;font:400 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;position:relative;text-shadow:none;z-index:8050}
.fancybox-opened .fancybox-title{visibility:visible}
.fancybox-title-float-wrap{position:absolute;bottom:0;right:50%;margin-bottom:-35px;z-index:8050;text-align:center}
.fancybox-title-float-wrap .child{display:inline-block;margin-right:-100%;padding:2px 20px;background:transparent;background:rgba(0,0,0,.8);-webkit-border-radius:15px;-moz-border-radius:15px;border-radius:15px;text-shadow:0 1px 2px #222;color:#FFF;font-weight:700;line-height:24px;white-space:nowrap}
.fancybox-title-outside-wrap{position:relative;margin-top:10px;color:#fff}
.fancybox-title-inside-wrap{padding-top:10px}
.fancybox-title-over-wrap{position:absolute;bottom:0;left:0;color:#fff;padding:10px;background:#000;background:rgba(0,0,0,.8)}
@media only screen and (-webkit-min-device-pixel-ratio:1.5),only screen and (min--moz-device-pixel-ratio:1.5),only screen and (min-device-pixel-ratio:1.5) {
  #fancybox-loading,.fancybox-close,.fancybox-prev span,.fancybox-next span{background-image:url(/images/fancybox/fancybox_sprite@2x.png);background-size:44px 152px}
  #fancybox-loading div{background-image:url(/images/fancybox/fancybox_loading@2x.gif);background-size:24px 24px}
}
.isotope-item{z-index:2}
.isotope-hidden.isotope-item{pointer-events:none;z-index:1}
.isotope,.isotope .isotope-item{-webkit-transition-duration:.8s;-moz-transition-duration:.8s;-o-transition-duration:.8s;transition-duration:.8s}
.isotope{-webkit-transition-property:height,width;-moz-transition-property:height,width;-o-transition-property:height,width;transition-property:height,width}
.isotope .isotope-item{-webkit-transition-property:-webkit-transform,opacity;-moz-transition-property:-moz-transform,opacity;-o-transition-property:top,left,opacity;transition-property:transform,opacity}
.isotope.no-transition,.isotope.no-transition .isotope-item,.isotope .isotope-item.no-transition{-webkit-transition-duration:0;-moz-transition-duration:0;-o-transition-duration:0;transition-duration:0}
.isotope.infinite-scrolling{-webkit-transition:none;-moz-transition:none;-ms-transition:none;-o-transition:none;transition:none}
#infscr-loading{position:fixed;text-align:center;bottom:30px;left:42%;z-index:100;background:#fff;background:hsla(0,0%,100%,0.9);padding:20px;color:#222;font-size:15px;font-weight:700;-webkit-border-radius:10px;-moz-border-radius:10px;border-radius:10px}
.otw_portfolio_manager-format-image img,.fancybox-outer .fancybox-inner iframe{width:100%!important;margin:0}
.otw_portfolio_manager-portfolio-meta-item{color:#666}
.fancybox-inner body img{width:100%!important}
.otw_portfolio_manager-infinite-scroll,.otw-row.otw_portfolio_manager-portfolio-items-holder.otw_portfolio_manager-portfolio-newspaper{margin:0 auto!important}
.otw_portfolio_manager-carousel img,.otw_portfolio_manager-slider .slides img,.widget-img img{width:100%}
.otw_portfolio-manager-share-button-boxy::before{display:none!important}