/* ===================================
    Preloader
====================================== */
.page-loader-wrapper{position:fixed;width:100%;height:100%;background-color:#f3f3f3;z-index:9999;overflow:hidden;margin:0;padding:0}
.page-loader-wrapper-1{overflow:hidden;background-color:rgba(0,0,0,0.9)}
.page-loader-wrapper-1 .loader{width:60px;height:60px;margin:auto;position:absolute;left:0;right:0;top:-10%;bottom:0;z-index:999}
.sk-double-bounce{width:60px;height:60px;position:relative;margin:60px auto}
.sk-double-bounce .sk-child{width:100%;height:100%;border-radius:50%;background-color:#efaa15;opacity:.8;position:absolute;top:0;left:0;-webkit-animation:sk-doubleBounce 2s infinite ease-in-out;animation:sk-doubleBounce 2s infinite ease-in-out}
.sk-double-bounce .sk-double-bounce2{-webkit-animation-delay:-1s;animation-delay:-1s}
@-webkit-keyframes sk-doubleBounce {
    0%,100%{-webkit-transform:scale(0);transform:scale(0)}
    50%{-webkit-transform:scale(1);transform:scale(1)}
}
@keyframes sk-doubleBounce {
    0%,100%{-webkit-transform:scale(0);transform:scale(0)}
    50%{-webkit-transform:scale(1);transform:scale(1)}
}