/* ===================================
    19.1 Single Post
====================================== */
.blog-banner{background-image:url(/images/blog/blog-banner.jpg)}
h2.entry-title{margin:15px 0 25px 0 !important; font-family: '微軟正黑體',sans-serif; font-size:26px; font-weight: bold; line-height: 1.3em !important;}
.single-post{margin:0 0 40px 0; padding:20px; background: #f3f3f3; }
.single-post:last-child{margin:0}
.single-post .blog-image{margin:0 0 35px}
.single-post .blog-image img{width:100%}
.single-post.bg01{ background: #fff; }
h2.entry-title a:hover{color:#efaa15;text-decoration:none}
.entry-content{float:left;width:100%;color:#666;padding:0 0px 0 0}
.entry-content p{margin:0 0 10px 0; font-size: 20px; line-height: 1.5em;}
.entry-content a span{ margin-top: 10px; float: right; color: #946134; font-size: 20px; display: block; }
.entry-meta a{color:#666;text-decoration:none}
.entry-meta a .entry-date{color:#efaa15}
.pagination{ width:100%; margin:20px 0 0 0 !important; text-align: center;}
.pagination li{/*float:left*/;margin:0 10px 0 0; display: inline-block;}
.pagination li a{width:25px;height:25px; line-height: 23px; color: #333; display:block;border:none;padding:0;border-radius:0!important}
.pagination li a:hover{ color: #946134; }
.pagination li.active a, .pagination li.active a:hover{ color: #fff; background: #769967; }
.pagination li.prev a{background:url(/images/previous_img.png) no-repeat center center; background-size: cover;}
.pagination li.next a{background:url(/images/next_img.png) no-repeat center center; background-size: cover;}
.pagination li.first a{background:url(/images/first_img.png) no-repeat center center; background-size: cover;}
.pagination li.last a{background:url(/images/last_img.png) no-repeat center center; background-size: cover;}
.pagination li.prev a:hover{background:url(/images/previous_imghover.png) no-repeat center center; background-size: cover;}
.pagination li.next a:hover{background:url(/images/next_imghover.png) no-repeat center center; background-size: cover;}
.pagination li.first a:hover{background:url(/images/first_imghover.png) no-repeat center center; background-size: cover;}
.pagination li.last a:hover{background:url(/images/last_imghover.png) no-repeat center center; background-size: cover;}
.pagination li.back a{width:auto;height:25px; padding-left: 5px; padding-right: 5px;}
.single-blog-post{margin:0;padding:0 0 60px;border-bottom:1px solid #ddd}
.single-blog-post .blog-image{margin:30px 0}
.single-blog-post .entry-content{margin:0;padding:0 0 30px}
.single-blog-post .entry-content p{margin:0 0 20px}
.single-blog-post .pagination{margin:0 0 40px}
.author{ margin: 0 10px 0 0; padding:3px 8px !important; color: #fff; font-size: 16px; border-radius: 3px;}
.author.bg01{ background: #5f844f;}
.author.bg02{ background: #5087ad;}
.author.bg03{ background: #bd9a57;}
.name{margin:18px 0 0}
.name a{color:#431f09;font-size:24px}
.name span{font-size:14px;display:block;font-family:'Poppins',sans-serif}
.blog-comments li .comment-body{padding:45px 0;border-bottom:1px solid #ddd}
.comment-author{padding:0 0 0 80px}
.comment-author p,.comment-metadata{margin:0 0 20px}
.comment-author .fa{left:0;top:-6px;width:60px;height:60px;color:#efaa15;font-size:60px;position:absolute}
.blog-comments li .children li{padding-left:80px}
.comment-respond{padding:70px 0 0}
.comment-respond h2{font-size:30px}
.comment-form p input{float:left;width:100%;height:50px;box-shadow:none;border:none;padding:10px;border:1px solid #ddd}
.comment-form-author,.comment-form-email,.comment-form-url{float:left;width:32%;margin:0 2% 0 0}
.comment-form-url{margin:0}
.comment-form-comment{margin:30px 0;float:left;border:none;width:100%}
.comment-form-comment textarea{box-shadow:0 0 0 1px #ddd inset;height:200px;padding:20px;width:100%;border:none}
.form-submit button{color:#fff;font-size:18px;padding:20px 30px}
.posted-on { font-size: 16px; color:#5d5d5d;}
/* ===================================
    19.2 Single Post Responsive
====================================== */
@media screen and (max-width: 1199px) {
    .content-box .single-post h2{font-size:26px;line-height:32px}
    .single-post{margin:0 0 40px}
    .entry-content{padding:15px 0 0}
    .comment-respond{padding:40px 0 0}
    .content-box .comment-respond h2{font-size:24px;line-height:28px}
    .author{text-align:center;padding:0 0 30px}
    .author figure{margin:0 auto 15px;float:none!important}
    .blog-comments li .children li{padding-left:30px}
}
@media screen and (max-width: 767px) {
    .content-box .single-post h2{font-size:22px;line-height:24px}
    .single-post{margin:0 0 20px}
    .entry-content p{margin:0 0 20px}
    .single-blog-post .entry-meta{margin:0 0 20px}
    .blog-comments li .comment-body{padding:25px 0}
    .comment-form-author,.comment-form-email,.comment-form-url{width:100%;margin:0 0 10px}
    .comment-form-comment{margin:10px 0}
}
@media screen and (max-width: 480px) {
    .blog-comments{padding:0}
    .comment-author{padding-left:50px}
    .comment-author .fa{top:0;height:40px;width:40px;font-size:40px}
    .comment-author h3{font-size:20px}
    .comment-author p,.comment-metadata{margin:0 0 10px;font-size:13px}
}