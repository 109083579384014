/* ===================================
    Animation
====================================== */
[class*=".effect-"]{-webkit-backface-visibility:hidden;-moz-backface-visibility:hidden;backface-visibility:hidden;-webkit-perspective:1000;-moz-perspective:1000;perspective:1000px}
.effect-fade{opacity:0}
.effect-fade.in{opacity:1}
.effect-slide-left{-webkit-transform:translate3d(-30%,0,0);transform:translate3d(-30%,0,0);opacity:0}
.effect-slide-left.in{-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0);opacity:1}
.effect-slide-right{-webkit-transform:translate3d(30%,0,0);transform:translate3d(30%,0,0);opacity:0}
.effect-slide-right.in{-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0);opacity:1}
.effect-slide-top{-webkit-transform:translate3d(0,-30%,0);transform:translate3d(0,-30%,0);opacity:0}
.effect-slide-top.in{-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0);opacity:1}
.effect-slide-bottom{-webkit-transform:translate3d(0,30%,0);transform:translate3d(0,30%,0);opacity:0}
.effect-slide-bottom.in{-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0);opacity:1}
.effect-pop{opacity:0}
.effect-pop.in{-webkit-animation:pop .6s ease-out .5s both;-moz-animation:pop .6s ease-out .5s both;-o-animation:pop .6s ease-out .5s both;animation:pop .6s ease-out .5s both;opacity:1 \9}
@-webkit-keyframes pop {
	0%{-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1)}
	50%{-webkit-transform:scale(1.2);-ms-transform:scale(1.2);transform:scale(1.2);opacity:1}
	100%{opacity:1;-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1)}
}
@-moz-keyframes pop {
	0%{-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1)}
	50%{-webkit-transform:scale(1.2);-ms-transform:scale(1.2);transform:scale(1.2);opacity:1}
	100%{opacity:1;-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1)}
}
@-o-keyframes pop {
	0%{-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1)}
	50%{-webkit-transform:scale(1.2);-ms-transform:scale(1.2);transform:scale(1.2);opacity:1}
	100%{opacity:1;-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1)}
}
@keyframes pop {
	0%{-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1)}
	50%{-webkit-transform:scale(1.2);-ms-transform:scale(1.2);transform:scale(1.2);opacity:1}
	100%{opacity:1;-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1)}
}
.effect-fall{opacity:0;-webkit-transform:scale(1.5);-ms-transform:scale(1.5);transform:scale(1.5)}
.effect-fall.in{-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1);opacity:1}
.effect-perspective{-webkit-perspective:1300px;-moz-perspective:1300px;perspective:1300px}
.effect-helix{-webkit-transform-style:preserve-3d;-moz-transform-style:preserve-3d;transform-style:preserve-3d;opacity:0;-webkit-transform:rotateY(-180deg);-ms-transform:rotateY(-180deg);transform:rotateY(-180deg)}
.effect-helix.in{opacity:1;-webkit-transform:rotateY(0);-ms-transform:rotateY(0);transform:rotateY(0)}