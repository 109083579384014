@media screen and (min-width: 768px) and (max-width: 1199px) {
.sub-banner h2{ margin-top: 103px; }
}

@media screen and (max-width: 1199px) {
    .banner-text h1 {
        font-size: 50px;
    }

    .banner-text > div{ max-width: 600px; margin: 388px auto 0 auto;}
    .design p br, .content-box h2 br{ display: none; }
    .content-box h2 {
        margin: 0 0 15px;
        font-size: 36px;
        line-height: 38px;
    }

    .design h3 {
         margin: 20px 0;
        font-size: 20px;
     }

    #woodart{
        margin: 40px 0 0;
    }

    #testimonials p {
        margin: 15px 0 0;
    }

    #testimonials {
        margin: 30px 0 0;
        font-size: 15px;
        line-height: 26px;
    }

    .partners-logo{
        margin: 30px 0 0;
    }

    #woodart .item figure img{
        width: 100%;
    }

    .art-textbox{
        margin: 0;
    }

    .art-textbox h3 {
        font-size: 25px;
        margin: 0 0 20px;
    }

    .otw_portfolio_manager-portfolio-text h3 {

        font-size: 24px;

    }

    .otw_portfolio_manager-portfolio-text h3 span{

        font-size: 14px;

    }

    .contact-form {

        margin: 30px 0 0;

    }

    .contact-img{

        display: none;

    }

    /****************ABOUT*************/

    .about-img {

        margin: 0;

    }

    .about-box{

        margin: 0 0 40px;

    }

    .small-box ul li{

        width: 49%;

    }

    .small-box ul li:last-child{

        float: right;

    }

    .small-box ul li .smallbox-inner p{

        min-height: 72px;

    }

    /***************CONTACT*****************/

    .mailing-info{

        padding: 20px 0;

        text-align: center;

    }

    .mailing-address p {

        margin: 0 0 30px;

    }

    .mailing-info figure {

        padding: 0;

        margin: 0 auto 10px;

        float: none !important;

    }

    .mailing-info .smallbox-inner p{

        margin: 0;

        min-height: 72px;

    }

    .maps,.maps iframe{

        height: 300px;

    }

    /****************SERVICES**************/

    .services {

        margin: 20px 0 0;

    }

    .price-table button{

        padding: 15px 6px;

    }

    .pakage_price span.numeric{

        font-size: 52px;

    }

    #bannerSlider  .left.carousel-control,

    #bannerSlider  .right.carousel-control{

        width: 36px;

        height: 36px;

    }

    #bannerSlider  .left.carousel-control{

        left: 2px;

    }

    #bannerSlider  .right.carousel-control{

        right: 2px;

    }

    #bannerSlider .carousel-control .sr-only{

        line-height: 36px;

    }

    .news_wrap h2{ margin-top: 15px; font-size: 16px !important; line-height: 1em !important; }

    .sub-banner img{ width: 150px; top: 135px; left: -70px; }

}

@media screen and (max-width: 991px) {

    .content-box h2 {

        font-size: 28px;

        line-height: 32px;

    }

    .design h3 {

        margin: 12px 0;

        font-size: 18px;

    }

    .design {

        font-size: 14px;

    }

    .design .transparent-btn {

        margin: 30px 0 0;

    }

    .banner-text > div{ margin: 340px auto 0 auto;}

    .banner-text h1 {

        /*font-size: 44px;*/

    }    

    .banner-text h1 br{ display: block !important; }

    .banner-text h3 {

        font-size: 20px;

        margin: 0 0 16px;

    }

    .banner-text p {

        /*margin: 20px 0 30px;*/

    }

    .services-box{

        margin: 10px 0;

        padding: 30px 0;

    }

    #woodart {

        margin: 30px 0 0;

    }

    .art-textbox {

        margin: 20px 0 0;

    }

    #woodart .carousel-control-prev, #testimonials .carousel-control-prev{

        left: 15px;

        right: 0;

        bottom: 0;

    }

    #woodart .carousel-control-next, #testimonials .carousel-control-next{

        bottom: 0;

        left: 75px;

    }

    #woodart .carousel-control, #testimonials .carousel-control{

        width: 100%;

        padding: 60px 0 0;

    }

    .faq-con{

        text-align: center;

    }

    .skill-level{

        margin: 20px 0 0;

    }

    .partners h2,.happy-clients{

        text-align: center;

    }

    .call-us h3{

        font-size: 26px;

    }

    /****************ABOUT*************/

    .small-box ul li{

        padding: 25px 10px;

        text-align: center;

    }

    .small-box ul li .smallbox-inner h3{

        margin: 15px 0 5px;

    }

    .about-box h3{

        font-size: 18px;

    }

    .about-box p {

        margin: 20px 0 0;

    }



    .ad{ width: 200px; }
    .float_r{ float: none; margin: 0 auto 20px auto; text-align: center; }

    .sub-banner img{ top: 130px; left: 0px; }

    .txt-con .table01 th, .txt-con .table01 td, .txt-con .table02 th, .txt-con .table02 td{ font-size: .813em;}
    .txt-con .table01 .td02{ height: 175px; }
    .txt-con .table01 .td03{ height: 177px; }

    .introduce{ margin: 20px auto 80px auto;}
    
    .intro_wrap .in_l2{ width: 100%; }
    .intro_wrap .in_r2{ display: none; }

    .history01 { width: 500px; margin:0 auto 20px auto; }

}

@media screen and (max-width: 940px){
#page_nav{ max-width: 900px; margin: 0 20px 50px 20px !important;}
}

@media screen and (min-width: 768px){
.txt-con .table02 .mb{ display: none; }
}

@media screen and (max-width: 768px){
#catalog{ margin: 0 0 30px 0;}
#catalog li:nth-child(1){ float: none; width: 100%; height: 150px; }
#catalog li:nth-child(2){ width: 50%; height: 300px; }
#catalog li:nth-child(3){ width: 50%; height: 300px; }
.cw01{ height: 100%; margin:0 10px; background: url('/images/cw-01.png') center center no-repeat; background-size: cover; border-radius: 5px; }
.cw02, .cw03, .cw04, .cw05{ height: 100%; margin: 20px 10px 10px 10px; background-position: center center;}
.catalog_wrap h4, .catalog_wrap2 h4{ width: 42%; padding:45px 0 45px 0; font-size: 18px; }

#page_nav{ margin: 0 20px 50px 20px !important; }

.services-box h4{ font-size: 26px !important; top: 30px; }

.otw_gap{ height: 0; }

.dk_show{ display: none; }
.mb_show{ display: block; }
}

@media screen and (max-width: 767px){

    .otw_portfolio_manager-portfolio-newspaper-filter,

    .otw_portfolio_manager-portfolio-newspaper-sort{

        margin: 24px 0 26px;

    }

    .partners-logo {

        margin: 20px 0 0;

    }

    .client-name{

        font-size: 24px;

    }

    .support{

        padding: 35px 0;

        text-align: center;

    }

    .call-us{

        margin: 0 0 20px;

    }

    .phone{

        text-align: center;

    }

    .phone-icon,.phone-numbers{

        float: none !important;

        display: inline-block;

        vertical-align: top;

    }

    .phone-numbers{

        text-align: left;

        padding: 0 0 0 5px;

    }

    .banner-text > div{ margin-top: 347px;}

    .carpenters {

        margin: 20px 0 0;

    }

    /*****************CONTACT**************/

    .mailing-info{

        margin: 5px 0 ;

    }

    .mailing-address{

        font-size: 13px;

    }

    .mailing-address p {

         margin: 0 0 20px;

     }

    .mailing-info .smallbox-inner p{

        min-height: auto;

    }

    .sub-banner{

        height: 182px;

    }

    .sub-banner h2{

        font-size: 32px;

    }

    .otw_portfolio_manager-portfolio-full{

        text-align: justify;

    }

    .animation-box{

        text-align: center;

    }

    .animation-box p{

        margin: 10px 0;

    }

    .animation-box .img-responsive{

        margin: 0 auto;

    }

    .title{

        margin: 0;

        padding:20px 0;

    }



    .title{

        border: none;

    }

    .otw_portfolio_manager-accordion.ui-accordion.ui-widget.ui-helper-reset{

        margin: 0 0 15px;

    }

    .otw_portfolio_manager-project-info-box-content{

        width: 100%;

    }

    .otw_portfolio-manager-share-button-boxy{

        display: inline-block;

        float: none;

    }

    .site-header .site-branding{ padding: 0 20px !important; }



    .app_wrap{ float: none; width: 100%; }
    .app_wrap:first-child{ display: none; }
    .app_wrap p{ max-width:100%; }

    .intro_wrap .in_img{ position: absolute; top: -4px; left: -4px; }
    .intro .in_l{ float: none; width: 100%;}
    .intro .in_r{ float: none; width: 100%; padding: 200px 40px 40px 40px; }

    .sub-banner img{ width: 120px; height: auto; top: 30px; left: 0px; }

    .txt-con .table01, .txt-con .table02{ float: none; width: 100%;}

    #page_nav li{ float: none !important; width: 100% !important;}

.services-box h4{ margin-left: -8px; top: 180px; }
.aside01{background: url('/images/service-01x.png') center center no-repeat; background-size: cover;}
.aside02{background: url('/images/service-02x.png') center center no-repeat; background-size: cover;}
.aside03{background: url('/images/service-03x.png') center center no-repeat; background-size: cover;}

.otw_wrap li{ width: 33.3333%; }

    }





@media screen and (max-width: 720px){

.ad{ width: 150px; }

}



@media screen and (max-width: 667px) {

.padding-top {

     padding-top: 34px;

}

.padding-bottom {

     padding-bottom: 34px;

}

    #accordion .panel-body{

        font-size: 13px;

        padding: 16px;

    }

    .otw_portfolio_manager-portfolio-newspaper-filter a,

    .otw_portfolio_manager-portfolio-newspaper-sort a{

        font-size: 13px;

        padding: 0 6px;

    }

    .banner-text{

        font-size: 16px;

    }

    .banner-text h1 {

        font-size: 40px;

    }

    .design{

        font-size: 13px;

    }

    body, select, input{

        line-height: 22px;

    }

    .otw_portfolio_manager-portfolio-text h3 {

        font-size: 20px;

    }

    #testimonials .carousel-control {

        padding: 80px 0 0;

    }

    #testimonials .carousel-control-prev,

    #testimonials .carousel-control-next{

        bottom: 15px;

    }

    .small-box ul li{

        width: 100%;

    }

    .small-box ul li .smallbox-inner p{

        min-height: auto;

    }

    .gallery-box .otw_portfolio_manager-3-3,

    .gallery-box .otw_portfolio_manager-3-3 .otw_portfolio_manager-portfolio-media a img {

        height: 240px !important;

    }

    .gallery-box .otw_portfolio_manager-3-3 .otw_portfolio_manager-portfolio-icon-wrapper .icon-link img,

    .gallery-box .otw_portfolio_manager-3-3 .otw_portfolio_manager-fancybox-img.icon-search img{

        height: auto !important;

    }

}

@media screen and (max-width: 600px) {

    .banner-text h1, .sub-banner h2 {

        font-size: 32px;

    }

    .content-box h2 {

        font-size: 24px;

        line-height: 28px;

    }

    .services-box p {

        margin: 15px 0 20px;

    }

    .skill-level,

    .call-us h3 span{

        font-size: 15px;

    }

    .skill-level .numeric {

        font-size: 30px;

        line-height: 30px;

        margin: 0 0 10px;

    }

    .contact-form ul li, .comment-box{

        width: 100%;

        margin: 0 0 10px;

    }

    .comment-box textarea {

        height: 170px;

    }

    .phone-numbers{

        font-size: 26px;

    }

    .client-name {

        font-size: 20px;

    }

    /*******************SERVICES***************/

    .wood-box h2 span{

        font-size: 13px;

    }



    .board-con.padding-top, .plan-con.padding-top{ padding-top:50px !important; }

    .board-con.padding-bottom, .plan-con.padding-bottom{ padding-bottom:50px !important; }

    .app_wrap{ padding:50px 60px;}

    .info-con{ padding: 50px 20px;}

    .design{ padding-bottom:0px;}

#catalog{ height: auto; margin-bottom: 60px;}
#catalog li:nth-child(1){ float: none; width: 100%; height: 150px; }
#catalog li:nth-child(2){ float: none; width: 100%; height: 300px; margin-bottom: 40px; }
#catalog li:nth-child(3){ float: none; width: 100%; height: 300px; }
.cw01{ height: 100%; margin:0 10px; background: url('/images/cw-01.png') center center no-repeat; background-size: cover; border-radius: 5px; }
.cw02, .cw03, .cw04, .cw05{ height: 100%; margin: 20px 10px 20px 10px !important; background-position: center center;}
.catalog_wrap h4, .catalog_wrap2 h4{ width: 42%; padding:45px 0 45px 0; font-size: 18px;}

}



@media screen and (max-width: 564px){

.banner-text p br{ line-height: 1.2em; display: block !important;}

.info-con h3 br{ display: block;}

.ad{ width: 250px; }

.otw{ margin: 0 10px;}
.otw_wrap{ margin: 50px 10px 0 10px !important;}
.otw_wrap li{ width: 50%; }
.otw_wrap li a{ font-size: 15px;}
.otw_wrap .otw_box{ margin: 10px;}

}



@media screen and (max-width: 480px) {

.banner-con{height:460px; padding: 0;}
.banner-con .container{padding: 0;}
.banner-text{padding: 0; }
.banner-text > div{ margin: 165px auto 0 auto; padding: 0;}

.banner-text p, .sub-banner p{ font-size:18px;}



    .otw_portfolio_manager-portfolio-newspaper-filter li,

    .otw_portfolio_manager-portfolio-newspaper-sort li {

        width: 32%;

        text-align: center;

    }

    .art-textbox,

    .call-us h3{

        line-height: 22px;

 }

    #accordion .panel-title a{

        font-size: 15px;

        line-height: 15px;

        padding: 10px 20px;

}

    .skill-level{

        height: 140px;

    }

    .call-us h3 span{

        margin: 10px 0 0;

    }

    .contact-box,

    .banner-text{

        font-size: 14px;

    }

    .br_mobile{display: block;}

    .banner-text h3,

    .design h3{

        font-size: 16px;

    }

    .content-box h2{

        font-size: 22px;

    }

    .services-box{

        padding: 20px 10px;

    }

    .services-box p br{

        display: none;

    }

    .art-textbox h3 {

        font-size: 22px;

        margin: 0 0 15px;

    }

    .skill-level{

        font-size: 14px;

        line-height: 18px;

    }

    #testimonials{

        text-align: center;

        margin: 20px 0 0 ;

        font-size: 13px;

        line-height: 22px;

    }

    #testimonials figure{

        float:none !important;

    }

    .client-name{

        padding: 10px 0 0;

        height: auto;

        width: 100%;

    }

    #testimonials .carousel-control {

        padding: 72px 0 0;

    }

    #testimonials .carousel-control-prev,

    #testimonials .carousel-control-next{

        left: -48px;

        right: 0;

        margin: 0 auto;

    }

    #testimonials .carousel-control-next{

        left: 48px;

    }

    .carpenters {

        margin: 10px 0 0;

    }

    .about-img img{

        width: 87%

    }

    .about-box h3 {

        font-size: 16px;

    }



    .services-box h4{ top: 60px; }



    .info-con h3{ font-size: 24px;}

    .info span{ width: 100%; }

    .info p{ float: none; width: 100%; }

    .cnt-con h3, .introduce{ font-size: 26px; }
    .cnt-con h4{ font-size: 22px; line-height: 30px; }
    .introduce{ font-size: 16px; }

    .faq_wrap h3, .faq_wrap h3 i{ font-size: 20px; }
    .faq_wrap h3{ padding:12px 10px 12px 50px; background:#769967 url('/images/icon-question.png') left 15px center no-repeat; background-size: auto 25px; }
    .faq_wrap h3 i{ left: 55px; }

    .txt-con .sec_wrap{ overflow: hidden; }
    .txt-con .sec_wrap span, .txt-con .sec_wrap.type2 span, .txt-con .sec_wrap.type3 span{ float: none; width: 100%; }
    .txt-con .sec_wrap p, .txt-con .sec_wrap.type2 p, .txt-con .sec_wrap.type3 p{ float: none; width: 100%; }

    .sub-banner img{ top: 30px; left: 0px; }

    .txt-con p, .txt-con span, .table01 th, .table01 td, .in_r p, .txt-con ul.list01 li, .intro_wrap .in_l2 p{ font-size: 16px !important; line-height: 1.8em !important; }

    .sub-banner{height:400px;}
    .sub-banner i{display: none; }
    .sub-banner br{display: block;}
    .sub-banner h2, .sub-banner p{ text-align: center;}
    .sub-banner > div{display:table-cell;vertical-align:middle}
    .about-banner{background-image:url(/images/about-banner_m.jpg)}

    .sub-banner img{ width: 100px; height: auto; margin:-75px auto 0 auto; position: static; }

    #catalog{ margin-bottom: 60px; }
    #catalog li:nth-child(1){ height: 100px; }
    #catalog li:nth-child(2){ height: 200px; }
    #catalog li:nth-child(3){ height: 200px; }
    .catalog_wrap h4, .catalog_wrap2 h4{ width: 42%; padding:30px 0 25px 0; font-size: 16px; line-height: 1.3em; background-size: auto 20px; }

    .introduce{ margin: 20px auto 60px auto;}

    #page_nav{ margin: 0 20px 40px 20px !important; }

    .services-box h4{ top: 65px; }

.site-header .site-branding{ width: 260px; }

.site-header .navbar-default .navbar-toggle{ margin:1px 0px 0 0;}

.services-box .dk{ display: none; }
.services-box .mb{ display: block; }


.app-banner{background-image:url(/images/app-banner_m.jpg);}
.app1-banner{background-image:url(/images/app1-banner_m.jpg);}
.app2-banner{background-image:url(/images/app2-banner_m.jpg);}
.app3-banner{background-image:url(/images/app3-banner_m.jpg);}
.app4-banner{background-image:url(/images/app4-banner_m.jpg);}
.app5-banner{background-image:url(/images/app5-banner_m.jpg);}

}



@media screen and (max-width: 414px){

.services img{ width: 100%;}

.services .separator img{ width: auto;}

}



@media screen and (max-width: 375px) {

.partners-logo { padding: 0;}



}





















