/* ===================================
    18.1 Short Code
====================================== */
.content{padding:20px 20px 60px}
.nav-tabs.nav-stacked{border:none}
.nav-tabs.nav-stacked > li{margin-bottom:5.75px;margin-left:0;margin-top:2px}
.nav-tabs.nav-stacked > li > a{-moz-border-bottom-colors:none;-moz-border-left-colors:none;-moz-border-right-colors:none;-moz-border-top-colors:none;background-color:#f3f3f3;border-color:-moz-use-text-color -moz-use-text-color -moz-use-text-color #efaa15;border-image:none;border-radius:0;border-style:none none none solid;border-width:0 0 0 5px;margin:0;color:#333;padding:14px 18px;transition:background .3s ease-out 0;-webkit-transition:background .3s ease-out 0;-moz-transition:background .3s ease-out 0;-ms-transition:background .3s ease-out 0}
.nav-tabs.nav-stacked > li.active > a,.nav-tabs.nav-stacked > li.active > a:hover,.nav-tabs.nav-stacked > li.active > a:focus{-moz-border-bottom-colors:none;-moz-border-left-colors:none;-moz-border-right-colors:none;-moz-border-top-colors:none;background-color:#efaa15;border-color:#0f1923 #0f1923 rgba(0,0,0,0);color:#fff;cursor:default;margin-top:0;outline:medium none;border-width:0 0 0 5px;transition:all .2s ease 0}
.nav-tabs.nav-stacked > li a:hover{color:#efaa15}
.the-icons li{list-style:outside none none}
.tabbable .nav-tabs{border:none}
.dmbutton2{background:rgba(0,0,0,0) none repeat scroll 0 0;border:1px solid #2b2e31;border-radius:2px;color:#2b2e31;cursor:pointer;display:inline-block;font-size:1.22rem;font-weight:400!important;letter-spacing:.25px;line-height:normal;margin:0 0 1.25rem;padding:.75rem 1.25rem .95rem;text-align:center;text-decoration:none;transition:background-color 300ms ease-out 0;-webkit-transition:background-color 300ms ease-out 0;-ms-transition:background-color 300ms ease-out 0;-moz-transition:background-color 300ms ease-out 0}
.carousel-slider1 a.carousel-control{background-color:#333;bottom:0;color:#eee;font-size:25px;font-weight:400;height:30px;left:0;line-height:25px;position:absolute;text-align:center;text-shadow:0 1px 2px rgba(0,0,0,0.6);width:30px;z-index:15;top:inherit}
.carousel-slider1 a.carousel-control.right{left:30px}
.carousel-slider1 .carousel-indicators li{background-color:rgba(255,255,255,0.25);border-radius:50px;cursor:pointer;display:inline-block;height:10px;margin:2px 4px;text-indent:-999px;width:10px}
.carousel.thumbnail .carousel-caption{-moz-border-bottom-colors:none;-moz-border-left-colors:none;-moz-border-right-colors:none;-moz-border-top-colors:none;background-color:#fff;border:none;padding-bottom:40px;position:relative;left:0;right:0;text-shadow:none}
.carousel.thumbnail .carousel-caption h4,.carousel.thumbnail .carousel-caption p{color:#1a1a1a}
.carousel-caption p{margin:0 0 10px}
.carousel.thumbnail .item{display:block!important;left:0!important;opacity:0;position:absolute;top:0;transition:opacity .7s ease-in-out 0;width:100%;z-index:1}
.carousel.thumbnail .carousel-control{background-color:transparent;bottom:1px;color:#999;left:auto;background-image:none;right:29px;top:inherit;z-index:99999999999;height:30px;width:4%;opacity:1}
.carousel.thumbnail .carousel-control.right{right:1px}
.carousel.thumbnail .carousel-control.left,.carousel.thumbnail .carousel-control.right{color:#000}
.carousel.thumbnail .item:first-child{position:relative;top:auto}
.carousel.thumbnail .item.active{opacity:1;transition:opacity .7s ease-in-out 0;z-index:2}
blockquote {margin: 20px;border-left: 5px solid #fcb100;text-align: left;line-height: 30px;font-size: 14px}
/* ===================================
    18.2 Progress Bar
====================================== */
.chart{margin-bottom:20px}
.percent{color:#0f1923;display:block;font-size:40px;font-weight:700;letter-spacing:-3px;line-height:210px;position:absolute;text-align:center;top:-3px;width:90%;z-index:10}
.progress-bar{color:#fff;font-size:11px;text-align:center;position:relative}
.progress-bar > span{background-color:rgba(0,0,0,0.25);bottom:0;left:0;line-height:25.5px;padding:0 10px;position:absolute;top:0}
/* ===================================
    18.3 Site Map
====================================== */
.sitemap-plan h4.title{margin-top:0}
.check li{list-style:outside none none;margin:8px 0}
.pricing li::before,.check li::before{content:"";font-family:"FontAwesome";font-size:16px;left:0;padding-right:5px;position:relative;top:2px;color:#477ab9}
.check li a{color:#000;text-decoration:none}
.check li a:hover{color:#477ab9}
/* ===================================
    18.4 Testimonial
====================================== */
.testimonial{-moz-border-bottom-colors:none;-moz-border-left-colors:none;-moz-border-right-colors:none;-moz-border-top-colors:none;background-color:#fff;border-color:-moz-use-text-color -moz-use-text-color #ddd;border-image:none;border-radius:0;border-style:none none dashed;border-width:0 0 1px;margin:20px 0 0;min-height:150px;padding:15px;text-align:left;transition:background .3s ease-out 0}
.testimonial::after{content:"";font-family:"FontAwesome";font-size:400%;opacity:.1;padding-right:5px;position:absolute;right:0;top:58px}
.testimonial img{display:inline-block;height:75px;margin-right:15px;margin-top:5px;max-width:100%;width:75px}
.testimonial p{margin:10px 0}
.alignleft{float:left}
.testimonial-meta{overflow:hidden}
.teammembers h4,.testimonial h4{font-size:16px;padding-right:5px}
/* ===================================
    18.5 Faq
====================================== */
.dmbox{background-color:#fff;border:1px solid #ededed;border-radius:0;margin:0;padding:9px 18px;text-align:center;transition:background .3s ease-out 0}
.dm-icon-effect-1 .dm-icon{transition:background .2s ease 0s,color .2s ease 0;-webkit-transition:background .2s ease 0s,color .2s ease 0;-moz-transition:background .2s ease 0s,color .2s ease 0;-ms-transition:background .2s ease 0s,color .2s ease 0}
.dm-icon-medium,.dm-icon{background:#2b2e31 none repeat scroll 0 0;border-radius:50%;color:#fff;cursor:pointer;display:inline-block;height:105px;margin:15px;position:relative;text-align:center;width:105px;position:relative;z-index:1;-webkit-transition:all 300ms linear;-moz-transition:all 300ms linear;-o-transition:all 300ms linear;-ms-transition:all 300ms linear;transition:all 300ms linear}
.dm-icon-medium::before,.dm-icon::before{display:block;font-style:normal;font-variant:normal;font-weight:400;line-height:105px;text-transform:none}
.dm-icon-effect-1 .dm-icon:hover{background:#477ab9;-webkit-transform:rotate(20deg);-moz-transform:rotate(20deg);-ms-transform:rotate(20deg);transform:rotate(20deg)}
.divider{display:block;height:2px;margin:25px 0;overflow:hidden;position:relative}
.title{color:#0f1923;margin:20px 0;padding-bottom:20px;position:relative;border-bottom:1px solid #ddd}
hr.space{margin-bottom:20px;margin-top:20px}
.heading1{color:#000;line-height:1.1;font-size:48px}
ul li ul,ul li ol{margin-bottom:0;margin-left:1.25em;list-style:inside}
blockquote{border-left:5px solid #efaa15}
.blockquote-reverse,blockquote.pull-right{border-right:5px solid #efaa15}
.list-group-item.active,.list-group-item.active:focus,.list-group-item.active:hover{background-color:#efaa15;border-color:#efaa15}
.list-group-item.active .list-group-item-text,.list-group-item.active:focus .list-group-item-text,.list-group-item.active:hover .list-group-item-text{color:#0f1923}
a.list-group-item:focus,a.list-group-item:hover,button.list-group-item:focus,button.list-group-item:hover{background-color:#f3f3f3}
.nav-pills > li.active > a,.nav-pills > li.active > a:focus,.nav-pills > li.active > a:hover{background-color:#efaa15;color:#fff;border-radius:0}
.nav > li > a:focus,.nav > li > a:hover{background-color:#f3f3f3}
.btn-group-lg > .btn,.btn-lg{border-radius:0;font-size:18px;line-height:1.33333;padding:15px 36px}
.margin-bottom-sm{margin:0 0 10px}
/* ===================================
    18.6 Tabs
====================================== */
.tab-content{border-color:#ddd;border-style:solid;border-width:0 1px 1px;overflow:auto;padding:10px}
.tabs-left > .nav-tabs{float:left;margin-right:0}
.tabs-left > .nav-tabs > li,.tabs-right > .nav-tabs > li{float:none}
.tabs-left > .nav-tabs .active > a,.tabs-left > .nav-tabs .active > a:hover,.tabs-left > .nav-tabs .active > a:focus{border-color:#ddd rgba(0,0,0,0) #ddd #ddd;margin-left:0;margin-right:-2px;padding:8px 16px}
/* ===================================
    18.7 Accordion
====================================== */
.accordion-heading .accordion-toggle{background-color:#fff;border-top:1px solid #f5f5f5;color:#1a1a1a;cursor:pointer;display:block;line-height:28.75px;outline:medium none!important;padding:5px 15px;text-decoration:none;text-transform:uppercase}
.accordion-heading .accordion-toggle > i{margin-right:10px}
.accordion-heading .accordion-toggle.active,.accordion-heading .accordion-toggle.active i,.accordion-heading .accordion-toggle:hover{color:#efaa15}
#accordion-panel .panel-title > .small,#accordion-panel .panel-title > .small > a,#accordion-panel .panel-title > a,#accordion-panel .panel-title > small,#accordion-panel .panel-title > small > a{color:#0f1923}
#accordion-panel2 .panel-success > .panel-heading{background-color:#efaa15;border-color:#efaa15;color:#fff}
.panel-title > .small,.panel-title > .small > a:hover,.panel-title > a,.panel-title > small,.panel-title > small > a:hover{color:#fff}
.thumbnail{background-color:#fff;border:1px solid #ddd;border-radius:4px;display:block;line-height:1.42857;margin-bottom:20px;padding:4px;transition:border .2s ease-in-out 0}
/* ===================================
    18.8 Carousel
====================================== */
.carousel .carousel-indicators{float:right;left:inherit;margin:0;right:20px;width:auto}
.carousel-control .glyphicon-chevron-left,.carousel-control .icon-prev{margin-left:-15px}
.caption-right{bottom:0;left:auto;right:0;top:0;width:35%}
.carousel-caption{padding:15px;background:rgba(0,0,0,0.75) none repeat scroll 0 0;bottom:0;color:#fff;padding-bottom:20px;padding-top:20px;position:absolute;right:0;text-align:center;text-shadow:0 1px 2px rgba(0,0,0,0.6);z-index:10}
.carousel-control .glyphicon-chevron-right,.carousel-control .icon-next{margin-right:-15px}
.carousel-control .glyphicon-chevron-left,.carousel-control .glyphicon-chevron-right,.carousel-control .icon-next,.carousel-control .icon-prev{font-size:30px;height:30px;margin-top:-15px;width:30px}
.caption-left{left:0;top:0;width:35%}
.nav-tabs > li > a{border-radius:0}
.full-caption{left:0}
.progress{height:25.5px;border-radius:0}
.panel-heading,.panel-group .panel{border-radius:0}
/* ===================================
    18.9 Plans
====================================== */
.plan1{padding:35px 5%;margin:30px 0 0;background:#fff;transition:ease-in-out .6s;-webkit-transition:ease-in-out .6s;-moz-transition:ease-in-out .6s;-ms-transition:ease-in-out .6s;border:1px solid #d4d4d4}
.plan1 h3{margin:0 0 15px;color:#333;font-weight:600;text-transform:uppercase}
.pakage_price{float:left;width:100%;font-size:16px;color:#333}
.pakage_price span.doller{font-size:20px;position:relative;top:-25px}
.pakage_price span.numeric{font-size:62px;color:#efaa15;line-height:60px}
.pakage_price span.blue{font-size:24px}
.plan1 ul{list-style:none;padding:0;margin:15px 0;float:left;width:100%}
.plan1 ul li{border-bottom:1px solid rgba(0,0,0,0.2);padding:10px 0}
.plan1 ul li:last-child{border:none}
.plan1 .btn{border-radius:2px;font-size:16px;line-height:16px;border:none}
.plan1:hover{background:#efaa15;transform:scaleY(1.09);-webkit-transform:scaleY(1.09);-moz-transform:scaleY(1.09);-ms-transform:scaleY(1.09);border-color:#efaa15}
.plan1:hover h3,.plan1:hover .pakage_price,.plan1:hover .pakage_price span.numeric,.plan1:hover ul li,.plan1:hover .pakage_price span.blue{color:#fff}
.plan1:hover .btn{color:#fff;background-color:#0f1923}
/* ===================================
    18.10 Table
====================================== */
table {margin: 0 0 1.5em;width: 100%;}
th {color: #efaa15;}
td, th {padding: 3px;border: 1px solid #ddd;text-align: center;}
/* ===================================
    18.11 Definition Lists
====================================== */
dl {margin-top: 0;margin-bottom: 20px;}
dt {font-weight: bold;color: #efaa15;}
dd {margin: 0 1.5em 1.5em;}